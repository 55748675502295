import Main from "@pacvue/frame/layout/Main.vue"
import { useRouterInterceptor } from "./useRouterInterceptor"
let recommendationsInterceptor = useRouterInterceptor() || {}
export default {
  router: {
    path: "/recommendation",
    component: Main,
    meta: { auth: true, id: -1, alias: "recommendation" },
    // meta: { menu: "AMC", subMenu: "AMC", alias: "AMC_Report", title: ["AMC Console"] },
    redirect: "/recommendation/index",
    children: [
      {
        path: "/recommendation/index",
        component: () => import("./views/index.vue"),
        meta: { auth: true, id: -1, alias: "recommendation" },
        name: "Recommendation",
        ...recommendationsInterceptor
      }
    ]
    // redirect: "/AMC",
  }
}
