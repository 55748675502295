import { requestData as request } from "@recommendations/utils/requestData"
let VITE_APP_BASEURL = import.meta.env.VITE_APP_BASEURL
const VITE_APP_META = import.meta.env.VITE_APP_META
export function getCampaignWithPage(data, { cacheKey = "campaignIds", cacheKeyPrefix = "" } = {}) {
  return request(
    {
      url: VITE_APP_META + "apply/campaigns/v2",
      method: "post",
      data
    },
    { cacheKey, cacheKeyPrefix }
  )
}
export function getAdgroupWithPage(data, { cacheKey = "adgroupIds", cacheKeyPrefix = "" } = {}) {
  return new Promise((resolve, reject) => {
    request(
      {
        url: VITE_APP_META + "apply/adGroups/v2",
        method: "post",
        data
      },
      { cacheKey, cacheKeyPrefix }
    )
      .then((data) => {
        resolve(data)
      })
      .catch((ex) => {
        reject(ex)
      })
  })
}
export function GetAdByAdgroupWithPage(data, { cacheKey = "adIds", cacheKeyPrefix = "" } = {}) {
  return new Promise((resolve, reject) => {
    request(
      {
        url: VITE_APP_BASEURL + "Ad/v3/GetPagedAdByAdgroup",
        method: "post",
        data
      },
      { cacheKey, cacheKeyPrefix }
    )
      .then((data) => {
        resolve(data)
      })
      .catch((ex) => {
        reject(ex)
      })
  })
}
export function getCampaign(data, { cacheKey, cacheKeyPrefix = "" } = {}) {
  return request(
    {
      url: VITE_APP_META + "apply/campaigns",
      method: "post",
      data
    },
    { cacheKey, cacheKeyPrefix }
  )
}
export function getAdgroup(data, { cacheKey, cacheKeyPrefix = "" } = {}) {
  return new Promise((resolve, reject) => {
    request(
      {
        url: VITE_APP_META + "apply/adGroups",
        method: "post",
        data
      },
      { cacheKey, cacheKeyPrefix }
    )
      .then((data) => {
        data = data || []
        var realData = []
        data.forEach((dataItem) => {
          var children = dataItem.children || []
          if (children.length) {
            var leafDatas = children.map((subItem) => {
              return {
                ...subItem,
                profileId: dataItem.pId,
                profileName: dataItem.pName,
                campaignId: dataItem.id,
                campaignName: dataItem.name
              }
            })
            realData.push(...leafDatas)
          } else {
            //realData.push(dataItem)
          }
        })
        resolve(realData)
      })
      .catch((ex) => {
        reject(ex)
      })
  })
}
export function GetAdByAdgroup(data, { cacheKey, cacheKeyPrefix = "" } = {}) {
  return new Promise((resolve, reject) => {
    request(
      {
        url: VITE_APP_BASEURL + "Ad/v3/GetAdByAdgroup",
        method: "post",
        data
      },
      { cacheKey, cacheKeyPrefix }
    )
      .then((data) => {
        data = data || []
        var realData = []
        data.forEach((dataItem) => {
          realData.push({
            ...dataItem,
            profileId: dataItem.Profileid,
            campaignId: dataItem.CampaignId,
            adgroupId: dataItem.AdGroupId,
            id: dataItem.AdId,
            name: dataItem.Name
          })
        })
        resolve(realData)
      })
      .catch((ex) => {
        reject(ex)
      })
  })
}
export function campaignTagsByProfile(data) {
  return request({
    url: VITE_APP_META + "/apply/campaignTagsByProfile",
    method: "post",
    data
  })
}
export function IsCurrentUserCampaignTagPermissionOn(data) {
  return request({
    url: `${VITE_APP_BASEURL}Account/V3/IsCurrentUserCampaignTagPermissionOn`,
    method: "get",
    data: data
  })
}
