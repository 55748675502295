import { InitApp } from "../src/frame"
// store
import * as storeModules from "@/store/modules/index"
import { clearSessionWithIgnoredKeys } from "../store/enumerate"
// utils
import { pacvueToken } from "@pacvue/utils"
// constants
import { isDev, isCN, isEU, isWhiteLabel, isProd } from "../constants/env"
import { DOMAIN_MAPPER_TEST, DOMAIN_MAPPER_PROD, LOCALIZED_PLATFORMS } from "../constants/platform"
// service
import { getCrossAuthCode } from "../src/api"
// hooks
import { useAnalyticsTrack } from "../src/analytics"

const constructRecentLoginQueue = (platform) => {
  let recentLoginQueue = localStorage.getItem("recentLoginQueue")?.split(",") ?? []
  recentLoginQueue = recentLoginQueue.filter((plt) => plt !== platform && plt !== "")
  // 若最近登录平台数目为 3, 则执行出队操作
  if (recentLoginQueue.length >= 3) {
    recentLoginQueue.pop()
  }
  // 目标平台入队
  recentLoginQueue.unshift(platform)
  return recentLoginQueue
}

const loginNavFn = async ({ targetPlatform } = {}) => {
  clearSessionWithIgnoredKeys()
  // 跳转准备
  const DOMAIN_MAPPER = isDev ? DOMAIN_MAPPER_TEST : DOMAIN_MAPPER_PROD
  let subDomain = DOMAIN_MAPPER[targetPlatform]?.subDomain ?? targetPlatform
  const pathPrefix = DOMAIN_MAPPER[targetPlatform]?.pathPrefix ?? ""
  const topDomain = isCN ? "cn" : "com"
  if (isEU) {
    const euSuffix = subDomain === "product" ? "europe" : "-eu"
    subDomain = subDomain + euSuffix
  }
  const params = new URLSearchParams()
  params.append("crossNav", "1")
  let url = ""
  if (window.location.href.includes("localhost")) {
    url = `http://localhost:3001/${pathPrefix}?${params.toString()}`
  } else {
    url = `${window.location.protocol}//${subDomain}.pacvue.${topDomain}/${pathPrefix}?${params.toString()}`
  }
  window.location.replace(url)
}

const writeLoginInfoAndNavigate = async ({ loginResponse, router, targetPlatform, isMultiClient = false, username = "" } = {}) => {
  username = username.toLowerCase()
  window.username = username
  localStorage.setItem("isMultiClient", isMultiClient)
  localStorage.setItem("loginUserName", username)
  const recentLoginQueue = constructRecentLoginQueue(targetPlatform)
  localStorage.setItem("recentLoginQueue", recentLoginQueue)
  const productLine = localStorage.getItem("productline")
  // 缓存 token
  pacvueToken.setPacvue_token(loginResponse)
  const commonStore = storeModules["useCommonStore"]()
  // 持久化写入当前 productLine
  commonStore.SET_PLATFORM(productLine)
  localStorage.setItem("SignFlag", true)
  // bol & chewy 老站点登录 或其他平台登进 bol & chewy, 需默认去新站点
  if (targetPlatform === "bol" && !commonStore.bolVersion) {
    targetPlatform = "bolv2"
  }
  // chewy 暂时不跳新站点
  // if (targetPlatform === "chewy" && !commonStore.chewyVersion) {
  //   targetPlatform = "chewyv2"
  // }
  if (productLine !== targetPlatform && !isWhiteLabel) {
    loginNavFn({ targetPlatform })
  } else {
    // 登录前先行重置 store 状态, 以免存在上次登录的缓存
    for (const storeName in storeModules) {
      storeModules[storeName]()?.$reset()
    }
    // 其实所有平台都已经写了 loginInterceptors 并 extend 到 InitApp 类
    if (InitApp.loginInterceptors && InitApp.loginInterceptors.length > 0) {
      for (let i in InitApp.loginInterceptors) {
        await InitApp.loginInterceptors[i](router)
      }
    }
  }
}

// 向 Segment 记录登录时长埋点
const recordLoginStartTime = ({ targetPlatform } = {}) => {
  if ((!isWhiteLabel || window.location.hostname.includes("groupm")) && isProd) {
    const productLine = window.productline || localStorage.getItem("productline")
    if (targetPlatform === productLine) {
      sessionStorage.setItem("loginStartTime", new Date().getTime())
    } else {
      sessionStorage.setItem("loginNavStartTime", new Date().getTime())
    }
  }
}

const logAndTrackLoginDuration = ({ to = {}, userInfo = {} } = {}) => {
  if (
    to.path !== "/Auth" &&
    localStorage.getItem("signFlagBillingTag") &&
    (sessionStorage.getItem("loginStartTime") || sessionStorage.getItem("loginNavStartTime")) &&
    (!isWhiteLabel || window.location.hostname.includes("groupm")) &&
    isProd &&
    !userInfo?.byPacvuer
  ) {
    if (!window.isCrossNav) {
      useAnalyticsTrack("Login Process Complete", {
        category: "Login",
        env: isCN ? "CN" : isEU ? "EU" : "US",
        "login duration": (new Date().getTime() - Number(sessionStorage.getItem("loginStartTime"))) / 1000
      })
    } else {
      useAnalyticsTrack("Login Navigation Process Complete", {
        category: "Login",
        env: isCN ? "CN" : isEU ? "EU" : "US",
        "login navigation duration": (new Date().getTime() - Number(sessionStorage.getItem("loginNavStartTime"))) / 1000
      })
    }
  }
  sessionStorage.removeItem("loginNavStartTime")
  sessionStorage.removeItem("loginStartTime")
}

export { loginNavFn, writeLoginInfoAndNavigate, constructRecentLoginQueue, recordLoginStartTime, logAndTrackLoginDuration }
