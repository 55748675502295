import { IsCurrentUserCampaignTagPermissionOn } from "@recommendations/apis/base"
import { useCommonStore } from "@/store"
export function useRouterInterceptor() {
  return {
    async beforeEnter(to, from, next) {
      const commonStore = useCommonStore()
      try {
        if (commonStore.camTagPermissionOn === undefined) {
          const res = await IsCurrentUserCampaignTagPermissionOn()
          commonStore.setCamTagPermissionOn(!!res)
        }
      } catch (ex) {
        console.log(ex)
      }
      next()
    }
  }
}
