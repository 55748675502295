/**
 * essentialPlugin.js
 * 跨平台通用拦截器逻辑
 */
import { app } from "./frame"
import useFrameStore from "./frameStore"
import { useUserStore, useAppStore, useCommonStore } from "@/store"
import { judgeClientAndRedirect } from "../utils/nav"

export default {
  // 用户相关拦截器
  userRelevantInterceptors: [
    // return thenable object
    (to, from, next) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId) {
            const userStore = useUserStore()
            const appStore = useAppStore()
            const commonStore = useCommonStore()
            if (!userStore.user?.userName) {
              await userStore.userAction({ to, from })
            }
            const shouldRedirect = await judgeClientAndRedirect({ userStoreContext: userStore })
            // 需要重定向到其他域名的情况下, 令 promise 持续 pending
            if (!shouldRedirect) {
              appStore.setUserPersist()
              commonStore.setUserPersist()
              app.config.globalProperties.$eventHub.emit("getUserInfo", userStore.user)
              onFulfill(true)
            }
          }
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // 菜单接口无需 userId, 故登录拿到 token 后可以立刻请求, 序列为 0
    (to, from, next) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId) {
            const frameStore = useFrameStore()
            if (frameStore.menu.length === 0) {
              await frameStore.meueAction({ to, from })
              // 保留 window.HomePage 字段, 来源由本地存储变为后端偏好返回
              window.HomePage = window.menuIdItemMap[window.HomePageId]?.url ?? ""
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    }),
    // 用户偏好接口现无需 userId, 故登录拿到 token 后可以立刻请求, 序列为 0
    (to, from, next) => ({
      syncSeq: 0,
      async then(onFulfill, onReject) {
        try {
          if (!to.query.shareId) {
            const userStore = useUserStore()
            if (!userStore.userSettings) {
              await userStore.userSetting({ to, from })
            }
            // 根据偏好切换主题
            if (userStore.userSettings) {
              if (userStore?.userSettings?.themeColour == "white") {
                document.getElementsByTagName("body")[0].setAttribute("class", "theme1")
              } else if (userStore?.userSettings?.themeColour == "black") {
                document.getElementsByTagName("body")[0].setAttribute("class", "theme2 borderversion")
              } else {
                document.getElementsByTagName("body")[0].setAttribute("class", "theme2")
              }
            }
          }
          onFulfill(true)
        } catch (err) {
          onReject(err)
        }
      }
    })
  ],
  settingInterceptors: []
}
