import Main from "@pacvue/frame/layout/Main.vue"

export default {
  router: {
    path: "/Event",
    component: Main,
    meta: { auth: true, id: -1 },
    redirect: "/Event/AdvertisingEvent",
    children: [
      {
        path: "/Event/AdvertisingEvent",
        component: () => import("@event/AdvertisingEvent/index.vue"),
        name: "Advertising Event",
        meta: {
          alias: "Event_AD",
          isShowTime: true,
          showCompareCheck: false,
          menu: "Advertising Event",
          menuMeta: [{ menuName: "Advertising Event" }]
        }
      },
      {
        path: "/Event/ASINEvent",
        component: () => import("@event/ASINEvent/index.vue"),
        name: "ASIN Event",
        meta: {
          alias: "Event_AD",
          isShowTime: true,
          showCompareCheck: false,
          menu: "ASIN Event",
          menuMeta: [{ menuName: "ASIN Event" }]
        }
      },
      {
        path: "/Event/PromotionEvent",
        component: () => import("@event/PromotionEvent/index.vue"),
        name: "Promotion Event",
        meta: {
          alias: "Event_AD",
          isShowTime: true,
          showCompareCheck: false,
          menu: "Promotion Event",
          menuMeta: [{ menuName: "Promotion Event" }]
        }
      },
      {
        path: "/Event/OperationEvent",
        component: () => import("@event/OperationEvent/index.vue"),
        name: "Operation Event",
        meta: {
          alias: "Event_AD",
          isShowTime: true,
          showCompareCheck: false,
          menu: "Operation Event",
          menuMeta: [{ menuName: "Operation Event" }]
        }
      },
      {
        path: "/Event/TaskCenter",
        component: () => import("@event/TaskCenter/index.vue"),
        name: "Task Center",
        meta: {
          alias: "Event_TS",
          noBreadcrumb: false,
          isShowTime: true,
          menu: "Task Center",
          showCompareCheck: true
        }
      },
      {
        path: "/Event/taskDetail",
        component: () => import("@event/TaskCenter/Detail/TaskDetail.vue"),
        name: "TaskDetail",
        meta: {
          alias: "Event_TS",
          parent: "Task Center",
          noBreadcrumb: false,
          isShowTime: false,
          showCompareCheck: true
        }
      },
      {
        path: "/Event/TaskOtherDetail",
        component: () => import("@event/TaskCenter/Detail/TaskOtherDetail.vue"),
        name: "TaskOtherDetail",
        meta: {
          alias: "Event_TS",
          parent: "Task Center",
          noBreadcrumb: false,
          isShowTime: true,
          showCompareCheck: true
        }
      }
    ]
  }
}
