import { pacvueToken } from "@pacvue/utils"

/**
 * 单纯解码 JWT, 注意不是通过公钥验证签名
 * @param {String} token Standard Json Web Token
 */
const decodeJWT = (token) => {
  const base64Url = token.split(".")[1]
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/")
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map((c) => "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2))
      .join("")
  )
  return JSON.parse(jsonPayload)
}

/**
 * 率先获取 token 中的 userId
 * @param {Object} userStoreContext userStore 上下文
 */
const decodeBasicUserInfoByToken = ({ userStoreContext = {} } = {}) => {
  if (!JSON.parse(sessionStorage.getItem("useInfo") || "{}").userId) {
    const tokenPayload = decodeJWT(pacvueToken.getPacvue_token().access_token)
    const { sub, userInfo } = tokenPayload
    const { ClientId, Level, availablePlatforms } = JSON.parse(userInfo || "{}")
    const simpleUserInfo = { userId: sub, clientId: ClientId, level: Level, availablePlatforms }
    userStoreContext.SET_USER(simpleUserInfo)
    localStorage.setItem("uid", sub)
    sessionStorage.setItem("useInfo", JSON.stringify(simpleUserInfo))
  }
}

export { decodeJWT, decodeBasicUserInfoByToken }
