import localForage from "localforage"
import { PRODUCT_LINE_ALL } from "../constants/platform"
import { rawLocalGetter, rawLocalRemover, rawLocalSetter } from "./storage-enhancer"

const productLine = localStorage.getItem("productline")
const { INDEXEDDB } = localForage
const forageInstance = localForage.createInstance({
  driver: INDEXEDDB,
  name: "db_persisted_data",
  storeName: `t_${productLine}`,
  description: "Platform-specific data copied from localStorage."
})
const regExp = /(_tWidth|_per_store_|LastSearch|__lastest-plan|Advertising_isActionExpand|storage_)/

const restoreStorageFromIndexedDB = async () => {
  await forageInstance.iterate((V, K) => {
    rawLocalSetter.call(localStorage, K, V)
  })
}

const clearIrrelevantPersistedData = () => {
  const len = localStorage.length
  const keys = []
  for (let i = 0; i < len; ++i) {
    keys.push(localStorage.key(i))
  }
  keys.forEach((key) => {
    const prefix = key.split("_")[0]
    if (PRODUCT_LINE_ALL.includes(prefix) && prefix !== productLine && regExp.test(key)) {
      rawLocalRemover.call(localStorage, key)
    }
  })
}

const keepCurrentPersistedData = async () => {
  // session 中若有 token, 则覆盖 local 中的 (local 也必须有)
  if (sessionStorage.getItem("Pacvue_token") && localStorage.getItem("Pacvue_token")) {
    localStorage.setItem("Pacvue_token", sessionStorage.getItem("Pacvue_token"))
  }
  localStorage.setItem("productline", window.productline)
  if (!["EN", "JA", "ZH"].includes(window.locale)) {
    window.locale = "EN"
  }
  localStorage.setItem("locale", window.locale)
  await restoreStorageFromIndexedDB()
  clearIrrelevantPersistedData()
}

const visibilityChangeHandler = async () => {
  if (document.visibilityState === "visible") {
    console.log("tab visibility: visible")
    keepCurrentPersistedData()
  }
}

document.addEventListener("visibilitychange", visibilityChangeHandler)

window.addEventListener("beforeunload", () => {
  document.removeEventListener("visibilitychange", visibilityChangeHandler)
})

export { forageInstance, keepCurrentPersistedData, regExp }
