import { PacvueGuide } from "@pacvue/element-plus"
import { createApp } from "vue"
import Guide from "./index.vue"
export function startMenuGuide({ elId } = {}) {
  const appShadowList = []
  const el = document.querySelector(elId)
  if (el) {
    el.classList.add("pacvue-menu-guide-step")
  }
  const defineSteps = [
    {
      element: elId,
      popover: {
        title: "",
        createDescription({ el } = {}) {
          var mask = createApp(Guide)
          mask.mount(el)
          appShadowList.push(mask)
          // mask.unmount()
        },
        position: "right-top"
      }
    }
  ]
  const guideHandler = PacvueGuide({
    steps: defineSteps,
    autoStart: false,
    classList: ["pacvue-menuGuideContent-wrapper"],
    extraOptions: {
      maxWidth: "initial",
      isRequireTitle: false,
      offset: -60,
      offsetX: 5,
      noPadding: true,
      onStepBtnChange(options = {}) {
        let reset = options.reset
        appShadowList.forEach((app) => {
          app.unmount()
        })
        if (options.isLast) {
          el.classList.remove("pacvue-menu-guide-step")
          let stageDom = document.getElementById("driver-highlighted-element-stage")
          if (stageDom) {
            stageDom.classList.remove("border-radius-12")
          }
          reset && reset()
        }
      }
    }
  })
  guideHandler.start()
  let stageDom = document.getElementById("driver-highlighted-element-stage")
  if (stageDom) {
    stageDom.classList.add("border-radius-12")
  }
}
