import { getInfo, getSettings } from "./api"
import { pacvueToken } from "@pacvue/utils"
import { initwebSocket } from "./websocket"
import { defineStore } from "pinia"
import { InitApp } from "./frame"

const state = () => {
  return {
    user: null,
    userSettings: null,
    userGuidanceInfo: null,
    sharePage: true
  }
}

function getCookie(cname) {
  var name = cname + "="
  var ca = document.cookie.split(";")
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i].trim()
    if (c.indexOf(name) == 0) return c.substring(name.length, 9)
  }
  return ""
}

const actions = {
  logout() {
    if (this.user) {
      this.user.userName = null
    }
  },
  userAction({ to, from } = {}) {
    let { getTokens } = pacvueToken
    return new Promise(async (resolve, reject) => {
      try {
        let userInfo = JSON.parse(sessionStorage.getItem("useInfo"))
        if (JSON.parse(sessionStorage.getItem("useInfo") || "{}").userName && !to.query.noCache) {
          window.socketErrCount = 0
        } else {
          userInfo = await getInfo(getTokens())
          sessionStorage.setItem("Pacvue_token", localStorage.getItem("Pacvue_token"))
          document.cookie = "grey=; expires=Thu, 01 Jan 1970 00:00:00 GMT;Path=/"
          sessionStorage.setItem("useInfo", JSON.stringify(userInfo))
          localStorage.setItem("urole", userInfo.level)
          window.socketErrCount = 0
        }
        this.SET_USER(userInfo)
        if (!localStorage.getItem("uid")) {
          localStorage.setItem("uid", userInfo?.userId)
          localStorage.setItem("uName", userInfo?.userName)
          localStorage.setItem("umail", userInfo?.mail)
        }
        initwebSocket()
        resolve(userInfo)
      } catch (error) {
        console.log(error)
        reject()
      }
    })
  },
  async userSetting({ to, from } = {}) {
    if (InitApp.settingInterceptors && Object.keys(InitApp.settingInterceptors).length > 0) {
      let phase = 0
      let currPhaseInterceptors = []
      while ((currPhaseInterceptors = InitApp.settingInterceptors[`phase${phase++}`] || []).length > 0) {
        const promises = currPhaseInterceptors.map((itc) => Promise.resolve(itc(this, to, from)))
        await Promise.all(promises)
      }
    } else {
      let setting = {}
      if (sessionStorage.getItem("setting")) {
        setting = JSON.parse(sessionStorage.getItem("setting"))
        if (setting.themeColour == "white") {
          document.getElementsByTagName("body")[0].setAttribute("class", "theme1")
        } else if (setting.themeColour == "black") {
          document.getElementsByTagName("body")[0].setAttribute("class", "theme2 borderversion")
        } else {
          document.getElementsByTagName("body")[0].setAttribute("class", "theme2")
        }
      } else {
        setting = await getSettings(this.user.userId)
        if (setting.themeColour == "white") {
          document.getElementsByTagName("body")[0].setAttribute("class", "theme1")
        } else if (setting.themeColour == "black") {
          document.getElementsByTagName("body")[0].setAttribute("class", "theme2 borderversion")
        } else {
          document.getElementsByTagName("body")[0].setAttribute("class", "theme2")
        }
      }
      this.SET_USERSETTINGS(setting)
    }
    window.HomePageId = this.userSettings?.homepageMenuId ?? -1
  },
  setUserGuidanceInfo(guidanceInfo) {
    this.userGuidanceInfo = guidanceInfo
  },
  SET_USER(user) {
    if (this.userSetting && this.userSetting.defaultCurrency) {
      user.defaultCurrency = this.userSetting.defaultCurrency
    }
    this.user = user
  },
  SET_USERSETTINGS(setting) {
    this.userSettings = setting
    sessionStorage.setItem("setting", JSON.stringify(setting))
    window.perSetting = setting
    if (this.user && setting.defaultCurrency) {
      this.user.defaultCurrency = setting.defaultCurrency
    }
  }
}

const getters = {
  isDemo(state) {
    return [432, 3438].includes(state.user?.clientId) || state.user?.userName?.toLowerCase() === "Demo@pacvue.com".toLowerCase() || state.user?.userName?.toLowerCase() === "Pacvue Demo".toLowerCase()
  },
  locale(state) {
    const userSettings = state.userSettings
    if (userSettings) {
      return userSettings.language || "EN"
    }
    if (window.location.href.includes(".cn")) {
      return "ZH"
    }
    return "EN"
  }
}

export default defineStore("user", {
  state,
  getters,
  actions
})
