import { isEmpty } from "lodash-es"

const findRootMenu = (menuItem) => {
  if (menuItem.parentId == 0) {
    return null
  } else {
    let targetItem = menuItem
    while (targetItem.parentId != 0) {
      targetItem = window.menuIdItemMap[targetItem.parentId]
    }
    return targetItem
  }
}

const isLeaf = (item) => {
  if (item.children === null || item.children === undefined) {
    return true
  } else if (Array.isArray(item.children) && item.children.length === 0) {
    return true
  }
  return false
}

const removeEmptyKeys = (obj) => {
  return Object.fromEntries(Object.entries(obj).filter(([key, value]) => key !== ""))
}

const menu2url = (list) => {
  return (list ?? []).reduce((prev, curr) => {
    if (isLeaf(curr)) {
      const { url, ...rest } = curr
      return { ...prev, [url]: rest }
    } else {
      const { children, url, ...rest } = curr
      // 此处有漏洞，可能是接口问题，本身为children的菜单，url为空
      return { ...prev, [url]: rest, ...menu2url(children) }
    }
  }, {})
}

const _menu2url = (list) => {
  let result = (list ?? []).reduce((prev, curr) => {
    if (isLeaf(curr)) {
      const { url, ...rest } = curr
      return { ...prev, [url]: rest }
    } else {
      const { children, url, ...rest } = curr
      let urls = children[0].url
      return { ...prev, [urls]: rest, ...menu2url(children) }
    }
  }, {})
  return removeEmptyKeys(result)
}

const path2root = (routes, routeName) => {
  const currentRoute = routes.find((r) => r.name === routeName)
  if (!currentRoute) {
    return []
  }
  const { meta, path, name, I18n } = currentRoute
  const { parent } = meta || {}
  if (parent) {
    return [...path2root(routes, parent), { path, name, I18n, meta }]
  } else {
    return [{ path, name, I18n, meta }]
  }
}

// todo这个方法有bug
const findMenuPath = (menuList, name) => {
  if (menuList.length === 0) {
    return []
  }
  for (const menu of menuList) {
    const { children, menuName, ...rest } = menu
    if (menuName === name) {
      return [{ ...rest, menuName }]
    }
    const subPath = findMenuPath(children || [], name)
    if (subPath.length > 0) {
      return [{ ...rest, menuName }, ...subPath]
    }
  }
  return []
}

const findMenuPathById = (menuList, id) => {
  if (menuList.length === 0) {
    return []
  }
  for (const menu of menuList) {
    const { children, menuId, ...rest } = menu
    if (menuId === id) {
      return [{ ...rest, menuId }]
    }
    const subPath = findMenuPathById(children || [], id)
    if (subPath.length > 0) {
      return [{ ...rest, menuId }, ...subPath]
    }
  }
  return []
}

const isRoot = (item) => {
  // parentId为0或者不存在为根节点
  return !item.parentId
}

const findMenus = (menus, menuId) => {
  const menu = menus.find((menu) => menu.menuId === menuId)
  if (!menu) {
    return []
  }
  if (isRoot(menu)) {
    return [menu]
  }
  const parentMenus = findMenus(menus, menu.parentId)
  return [...parentMenus, menu]
}

function flatTree(tree = [], pid = null) {
  if (isEmpty(tree)) {
    return []
  } else {
    let result = []
    if (Array.isArray(tree)) {
      for (const item of tree) {
        const { id, children, ...rest } = item
        result.push({ id, ...rest, pid })
        result = [...result, ...flatTree(children, id)]
      }
      return result
    } else {
      throw new Error("input data error!")
    }
  }
}

if (import.meta.vitest) {
  const { it, expect } = import.meta.vitest
  const list = [
    { alias: "Home", children: [], icon: "Home", menuId: 601, menuName: "Home", parentId: 0, url: "/Home" },
    {
      alias: "Product",
      children: [
        {
          alias: "PC_PC",
          children: [],
          icon: null,
          menuId: 701,
          menuName: "Product Dashboard",
          parentId: 602,
          url: "/ProductCenter/ProductDashboard"
        },
        {
          alias: "PC_SR",
          children: [
            {
              alias: "PC_PD",
              icon: null,
              menuId: 801,
              // children:[],
              menuName: "Product Detail",
              parentId: 702,
              url: "/ProductCenter/ProductListing/Detail"
            }
          ],
          icon: null,
          menuId: 702,
          menuName: "Product Listing",
          parentId: 602,
          url: "/ProductCenter/ProductListing"
        },
        {
          alias: "PC_VR",
          children: [],
          icon: null,
          menuId: 703,
          menuName: "Order",
          parentId: 602,
          url: "/ProductCenter/Order"
        }
      ],
      icon: "ProductCenter",
      menuId: 602,
      menuName: "Product Center",
      parentId: 0,
      url: "/Product/ProductsDashBoard"
    },
    {
      alias: "Budget",
      children: [],
      icon: "BudgetManager",
      menuId: 613,
      menuName: "BudgetManager",
      parentId: 0,
      url: "/Budget/BudgetDashboard"
    },
    {
      alias: "Adverting",
      children: [
        {
          alias: "Ad_E",
          children: [],
          icon: null,
          menuId: 705,
          menuName: "Explorer",
          parentId: 603,
          url: "/Campaign/AdvertisingExplore"
        },
        {
          alias: "Ad_Profile",
          children: [],
          icon: null,
          menuId: 706,
          menuName: "Profile",
          parentId: 603,
          url: "/Campaign/Profile"
        },
        {
          alias: "Ad_Tagging",
          children: [],
          icon: null,
          menuId: 707,
          menuName: "Tagging",
          parentId: 603,
          url: "/Campaign/CampaignTagging"
        },
        {
          alias: "Ad_Portfolio",
          children: [],
          icon: null,
          menuId: 708,
          menuName: "Portfolio",
          parentId: 603,
          url: "/Campaign/AmazonPortfolio"
        },
        {
          alias: "Ad_Campaign",
          children: [],
          icon: null,
          menuId: 709,
          menuName: "Campaign",
          parentId: 603,
          url: "/Campaign/Campaign"
        },
        {
          alias: "Ad_Adgroup",
          children: [],
          icon: null,
          menuId: 710,
          menuName: "Adgroup",
          parentId: 603,
          url: "/Campaign/AdGroup"
        },
        {
          alias: "Ad_Targeting",
          children: [],
          icon: null,
          menuId: 711,
          menuName: "Targeting",
          parentId: 603,
          url: "/Campaign/Keyword"
        },
        {
          alias: "Ad_Asin",
          children: [],
          icon: null,
          menuId: 712,
          menuName: "Asin",
          parentId: 603,
          url: "/Campaign/Asin"
        },
        {
          alias: "Ad_ST",
          children: [],
          icon: null,
          menuId: 713,
          menuName: "Search Term",
          parentId: 603,
          url: "/Campaign/Query"
        },
        {
          alias: "Ad_NT",
          children: [],
          icon: null,
          menuId: 714,
          menuName: "Negative Targeting",
          parentId: 603,
          url: "/Campaign/NegativeKeyword"
        },
        {
          alias: "Ad_BO",
          children: [],
          icon: null,
          menuId: 715,
          menuName: "Bulk Operations",
          parentId: 603,
          url: "/Campaign/BulkOperations"
        }
      ],
      icon: "Advertising",
      menuId: 603,
      menuName: "Adverting",
      parentId: 0,
      url: "/Adverting"
    },
    {
      alias: "Report",
      children: [
        {
          alias: "Report_DR",
          children: [],
          icon: null,
          menuId: 716,
          menuName: "Default Report",
          parentId: 604,
          url: "/Report/Index"
        },
        {
          alias: "Report_My",
          children: [],
          icon: null,
          menuId: 717,
          menuName: "My Report",
          parentId: 604,
          url: "/Report/MyReport"
        },
        {
          alias: "MarketPlaceDashboard",
          children: [],
          icon: null,
          menuId: 718,
          menuName: "Marketplace Dashboard",
          parentId: 604,
          url: "/Report/MarketPlaceDashboard"
        },
        {
          alias: "Report_AD",
          children: [],
          icon: null,
          menuId: 719,
          menuName: "Analytics Dashboard",
          parentId: 604,
          url: "/Analytics/Index"
        }
      ],
      icon: "Homereport",
      menuId: 604,
      menuName: "Report",
      parentId: 0,
      url: "/Report/Index"
    },
    {
      alias: "Research",
      children: [
        {
          alias: "Opt_KR",
          children: [],
          icon: null,
          menuId: 720,
          menuName: "Keyword Research",
          parentId: 605,
          url: "/Research/KeywordsCopy"
        },
        {
          alias: "Opt_PR",
          children: [],
          icon: null,
          menuId: 721,
          menuName: "PAT Research",
          parentId: 605,
          url: "/Research/PATsCopy"
        },
        {
          alias: "Opt_AR",
          children: [],
          icon: null,
          menuId: 722,
          menuName: "Audience Research",
          parentId: 605,
          url: "/Optimization/AudienceResearch"
        },
        {
          alias: "Opt_QAG",
          children: [],
          icon: null,
          menuId: 723,
          menuName: "Query-Asin Graph",
          parentId: 605,
          url: "/Optimization/QueryAsinGraph"
        },
        {
          alias: "Opt_BLC",
          children: [],
          icon: null,
          menuId: 724,
          menuName: "Bid Landscape Chart",
          parentId: 605,
          url: "/Research/KeywordScatter"
        },
        {
          alias: "Opt_ALC",
          children: [],
          icon: null,
          menuId: 725,
          menuName: "Asin Landscape Chart",
          parentId: 605,
          url: "/Optimization/AsinLandScape"
        },
        {
          alias: "Opt_CTU",
          children: [],
          icon: null,
          menuId: 726,
          menuName: "Campaign Tune-Up",
          parentId: 605,
          url: "/Optimization/NewCampaignTuneUp"
        }
      ],
      icon: "Research",
      menuId: 605,
      menuName: "Research",
      parentId: 0,
      url: "/Research/KeywordsCopy"
    },
    {
      alias: "Optimization",
      children: [
        {
          alias: "Op_Dayparting",
          children: [],
          icon: null,
          menuId: 727,
          menuName: "Dayparting Scheduler",
          parentId: 606,
          url: "/Optimization/Dayparting"
        },
        {
          alias: "Opt_Budget",
          children: [],
          icon: null,
          menuId: 728,
          menuName: "Budget Scheduler",
          parentId: 606,
          url: "/Optimization/Schedule"
        },
        {
          alias: "Opt_RBO",
          children: [],
          icon: null,
          menuId: 729,
          menuName: "Rule",
          parentId: 606,
          url: "/Rule/v2/#/index"
        },
        {
          alias: "Opt_AI",
          children: [],
          icon: null,
          menuId: 730,
          menuName: "AI",
          parentId: 606,
          url: "/Optimization/AutomationAI"
        },
        {
          alias: "Ad_AuditCenter",
          children: [],
          icon: null,
          menuId: 731,
          menuName: "Paid Search Audit",
          parentId: 606,
          url: "/AuditCenter"
        },
        {
          alias: "BE",
          children: [],
          icon: null,
          menuId: 732,
          menuName: "Bid Explorer",
          parentId: 606,
          url: "/Optimization/BidExplorer"
        }
      ],
      icon: "Optimization",
      menuId: 606,
      menuName: "Optimization",
      parentId: 0,
      url: "/Optimization/AiOptimization"
    },
    {
      alias: "DSP",
      children: [
        {
          alias: "DSP_Dashboard",
          children: [],
          icon: null,
          menuId: 733,
          menuName: "Dashboard",
          parentId: 607,
          url: "/DSP/Dashboard"
        },
        {
          alias: "DSP_Advertiser",
          children: [],
          icon: null,
          menuId: 734,
          menuName: "Advertiser",
          parentId: 607,
          url: "/DSP/Advertiser"
        },
        {
          alias: "DSP_Order",
          children: [],
          icon: null,
          menuId: 735,
          menuName: "Order",
          parentId: 607,
          url: "/DSP/Order"
        },
        {
          alias: "DSP_LI",
          children: [],
          icon: null,
          menuId: 736,
          menuName: "Line Items",
          parentId: 607,
          url: "/DSP/Lineitems"
        },
        {
          alias: "DSP_Creative",
          children: [],
          icon: null,
          menuId: 737,
          menuName: "Creative",
          parentId: 607,
          url: "/DSP/Creative"
        },
        {
          alias: "DSP_Audience",
          children: [],
          icon: null,
          menuId: 738,
          menuName: "Audience",
          parentId: 607,
          url: "/DSP/Audience"
        }
      ],
      icon: "SideBarDsp",
      menuId: 607,
      menuName: "DSP",
      parentId: 0,
      url: "/DSP/Dashboard"
    },
    {
      alias: "SDD",
      children: [],
      icon: "SponsoredDisplay",
      menuId: 608,
      menuName: "Sponsored Display",
      parentId: 0,
      url: "/SDDashboard/Index"
    },
    {
      alias: "CA",
      children: [
        {
          alias: "CA_Competitive",
          children: [],
          icon: null,
          menuId: 739,
          menuName: "Competitive",
          parentId: 609,
          url: "Analysis/Competitive"
        },
        {
          alias: "CA_PT",
          children: [],
          icon: null,
          menuId: 740,
          menuName: "Product Tracking",
          parentId: 609,
          url: "/Analysis/ProductTracking"
        },
        {
          alias: "CA_BA",
          children: [],
          icon: null,
          menuId: 741,
          menuName: "Brand Audit",
          parentId: 609,
          url: "/Analysis/BrandAudit"
        }
      ],
      icon: "CompetitorAnalysis",
      menuId: 609,
      menuName: "Competitor Analysis",
      parentId: 0,
      url: "/Analysis/Competitive"
    },
    {
      alias: "PT",
      children: [],
      icon: "PriceTracker",
      menuId: 610,
      menuName: "Price Tracker",
      parentId: 0,
      url: "/PriceTracker/Home"
    },
    {
      alias: "CI",
      children: [],
      icon: "CategoryIntelligence",
      menuId: 611,
      menuName: "Category Intelligence",
      parentId: 0,
      url: "/CategoryIntelligence/Index"
    }
  ]
  it("menu2url", () => {
    const result = menu2url(list)
    // console.log(result)
    expect(result).toBeTypeOf("object")
    expect(result["/Home"]).toEqual({ alias: "Home", icon: "Home", menuId: 601, menuName: "Home", parentId: 0 })
    expect(result["/ProductCenter/ProductDashboard"]).toEqual({
      alias: "PC_PC",
      icon: null,
      menuId: 701,
      menuName: "Product Dashboard",
      parentId: 602
    })
  })

  const routes = [
    {
      path: "/Optimization/Dayparting/Setting",
      meta: {
        title: "Dayparting Scheduler Setting",
        id: 52,
        breadcrumb: [
          {
            name: "Optimization",
            link: "/Optimization"
          },
          {
            name: "Dayparting Scheduler",
            link: "/Optimization/Dayparting"
          },
          {
            name: "Dayparting Setting",
            link: "/Optimization/Dayparting/Setting"
          }
        ]
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/Optimization/Dayparting/ApplyInfo",
      meta: {
        title: "Dayparting Scheduler Apply Info",
        id: 53,
        breadcrumb: [
          {
            name: "Optimization",
            link: "/Optimization"
          },
          {
            name: "Dayparting Scheduler",
            link: "/Optimization/Dayparting"
          },
          {
            name: "Dayparting Applied Items",
            link: "/Optimization/Dayparting/ApplyInfo"
          }
        ]
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/ProductCenter/ProductDashboard",
      name: "ProductDashboard",
      meta: {
        title: "ProductDashboard",
        id: 2
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/ProductCenter/ProductListing",
      name: "Product Listing",
      meta: {
        title: "ProductDashboard",
        id: 2
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/ProductCenter/Order",
      name: "Order",
      meta: {
        title: "ProductDashboard",
        id: 2
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {
        default: {
          __name: "ProductOrder",
          __hmrId: "9fc1eaf8",
          __scopeId: "data-v-9fc1eaf8",
          __file: "D:/work/vue3-admin/packages/main/src/views/ProductCenter/ProductOrder.vue"
        }
      }
    },
    {
      path: "/Analysis/SOVBrand",
      meta: {},
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/Analysis/SOVKeyword",
      meta: {},
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/Analysis/SOVAsin",
      meta: {},
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/Analysis/BrandsMapping",
      meta: {},
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/Analysis/KeywordDetail",
      meta: {
        title: "Competitive",
        id: 1,
        isShowTime: true,
        icon: '<span class="icon iconfont">&#xe83c;</span>',
        showCompareCheck: true,
        breadcrumb: [
          {
            name: "Competitive",
            link: "/"
          },
          {
            name: "KeywordDetail"
          }
        ]
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/Analysis/ProductTracking",
      meta: {
        title: "ProductTracking",
        id: 1,
        isShowTime: true,
        showCompareCheck: true,
        breadcrumb: [
          {
            name: "ProductTracking",
            link: "/Analysis/ProductTracking"
          }
        ]
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/Analysis/ProductTrackingDetail",
      meta: {
        title: "Product Detail",
        id: 1,
        isShowTime: true,
        showCompareCheck: true,
        breadcrumb: [
          {
            name: "ProductTracking",
            link: "/Analysis/ProductTracking"
          },
          {
            name: "Product Detail",
            link: "/Analysis/ProductTracking"
          }
        ]
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/Analysis/BrandAudit",
      meta: {
        title: "Brand Audit",
        id: 1,
        isShowTime: true,
        showCompareCheck: true,
        breadcrumb: [
          {
            name: "Brand Audit",
            link: "/Analysis/BrandAudit"
          }
        ]
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/Optimization/Dayparting",
      meta: {
        title: "Dayparting Scheduler",
        id: 51,
        breadcrumb: [
          {
            name: "Optimization",
            link: "/Optimization"
          },
          {
            name: "Dayparting Scheduler",
            link: "/Optimization/Dayparting"
          }
        ]
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/Home",
      meta: {
        title: "Home",
        id: 1,
        isShowTime: true,
        showCompareCheck: true,
        breadcrumb: [
          {
            name: "Home",
            link: ""
          }
        ]
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/ProductDetail",
      name: "Product Detail",
      meta: {
        parent: "Product Listing"
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/",
      meta: {
        auth: true,
        id: -1
      },
      props: {
        default: false
      },
      children: [
        {
          path: "/Home",
          meta: {
            title: "Home",
            id: 1,
            isShowTime: true,
            showCompareCheck: true,
            breadcrumb: [
              {
                name: "Home",
                link: ""
              }
            ]
          }
        },
        {
          path: "/ProductCenter/ProductDashboard",
          name: "ProductDashboard",
          meta: {
            title: "ProductDashboard",
            id: 2
          }
        },
        {
          path: "/ProductCenter/ProductListing",
          name: "ProductListing",
          meta: {
            title: "ProductDashboard",
            id: 2
          }
        },
        {
          path: "/ProductCenter/Order",
          name: "Order",
          meta: {
            title: "ProductDashboard",
            id: 2
          }
        },
        {
          path: "/ProductDetail",
          name: "Product Detail",
          meta: {
            parent: "Product Listing"
          }
        },
        {
          path: "/Analysis/SOVBrand"
        },
        {
          path: "/Analysis/SOVKeyword"
        },
        {
          path: "/Analysis/SOVAsin"
        },
        {
          path: "/Analysis/BrandsMapping"
        },
        {
          path: "/Analysis/KeywordDetail",
          meta: {
            title: "Competitive",
            id: 1,
            isShowTime: true,
            icon: '<span class="icon iconfont">&#xe83c;</span>',
            showCompareCheck: true,
            breadcrumb: [
              {
                name: "Competitive",
                link: "/"
              },
              {
                name: "KeywordDetail"
              }
            ]
          }
        },
        {
          path: "/Analysis/ProductTracking",
          meta: {
            title: "ProductTracking",
            id: 1,
            isShowTime: true,
            showCompareCheck: true,
            breadcrumb: [
              {
                name: "ProductTracking",
                link: "/Analysis/ProductTracking"
              }
            ]
          }
        },
        {
          path: "/Analysis/ProductTrackingDetail",
          meta: {
            title: "Product Detail",
            id: 1,
            isShowTime: true,
            showCompareCheck: true,
            breadcrumb: [
              {
                name: "ProductTracking",
                link: "/Analysis/ProductTracking"
              },
              {
                name: "Product Detail",
                link: "/Analysis/ProductTracking"
              }
            ]
          }
        },
        {
          path: "/Analysis/BrandAudit",
          meta: {
            title: "Brand Audit",
            id: 1,
            isShowTime: true,
            showCompareCheck: true,
            breadcrumb: [
              {
                name: "Brand Audit",
                link: "/Analysis/BrandAudit"
              }
            ]
          }
        },
        {
          path: "/Optimization/Dayparting",
          meta: {
            title: "Dayparting Scheduler",
            id: 51,
            breadcrumb: [
              {
                name: "Optimization",
                link: "/Optimization"
              },
              {
                name: "Dayparting Scheduler",
                link: "/Optimization/Dayparting"
              }
            ]
          }
        },
        {
          path: "/Optimization/Dayparting/Setting",
          meta: {
            title: "Dayparting Scheduler Setting",
            id: 52,
            breadcrumb: [
              {
                name: "Optimization",
                link: "/Optimization"
              },
              {
                name: "Dayparting Scheduler",
                link: "/Optimization/Dayparting"
              },
              {
                name: "Dayparting Setting",
                link: "/Optimization/Dayparting/Setting"
              }
            ]
          }
        },
        {
          path: "/Optimization/Dayparting/ApplyInfo",
          meta: {
            title: "Dayparting Scheduler Apply Info",
            id: 53,
            breadcrumb: [
              {
                name: "Optimization",
                link: "/Optimization"
              },
              {
                name: "Dayparting Scheduler",
                link: "/Optimization/Dayparting"
              },
              {
                name: "Dayparting Applied Items",
                link: "/Optimization/Dayparting/ApplyInfo"
              }
            ]
          }
        }
      ],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {
        default: {
          __name: "Main",
          __hmrId: "534021dc",
          __scopeId: "data-v-534021dc",
          __file: "D:/work/vue3-admin/packages/main/src/pacvue/layout/Main.vue"
        }
      }
    },
    {
      path: "/login",
      meta: {},
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    },
    {
      path: "/detail/:id",
      name: "Product Detail Son",
      meta: {
        parent: "Product Detail"
      },
      props: {
        default: false
      },
      children: [],
      instances: {},
      leaveGuards: {},
      updateGuards: {},
      enterCallbacks: {},
      components: {}
    }
  ]
  it("path2root", () => {
    let breadcrumb = path2root(routes, "Order")
    expect(breadcrumb).toEqual([{ path: "/ProductCenter/Order", name: "Order" }])
    breadcrumb = path2root(routes, "Product Detail")
    console.log(breadcrumb)
    expect(breadcrumb).toEqual([
      { path: "/ProductCenter/ProductListing", name: "Product Listing" },
      { path: "/ProductDetail", name: "Product Detail" }
    ])
    breadcrumb = path2root(routes, "Product Detail Son")
    expect(breadcrumb).toEqual([
      { path: "/ProductCenter/ProductListing", name: "Product Listing" },
      { path: "/ProductDetail", name: "Product Detail" },
      { path: "/detail/:id", name: "Product Detail Son" }
    ])
  })

  it("findMenuPath", () => {
    let result = findMenuPath(list, "Home")
    expect(result).toEqual([{ alias: "Home", icon: "Home", menuId: 601, menuName: "Home", parentId: 0, url: "/Home" }])
    result = findMenuPath(list, "Order")
    console.log(result)
    expect(result).toEqual([
      {
        alias: "Product",
        icon: "ProductCenter",
        menuId: 602,
        menuName: "Product Center",
        parentId: 0,
        url: "/Product/ProductsDashBoard"
      },
      {
        alias: "PC_VR",
        icon: null,
        menuId: 703,
        menuName: "Order",
        parentId: 602,
        url: "/ProductCenter/Order"
      }
    ])

    result = findMenuPath(list, "Product Detail")
    expect(result).toEqual([
      {
        alias: "Product",
        icon: "ProductCenter",
        menuId: 602,
        menuName: "Product Center",
        parentId: 0,
        url: "/Product/ProductsDashBoard"
      },
      {
        alias: "PC_SR",
        icon: null,
        menuId: 702,
        menuName: "Product Listing",
        parentId: 602,
        url: "/ProductCenter/ProductListing"
      },
      {
        alias: "PC_PD",
        icon: null,
        menuId: 801,
        menuName: "Product Detail",
        parentId: 702,
        url: "/ProductCenter/ProductListing/Detail"
      }
    ])
  })

  it("findMenus", () => {
    const allMenus = [
      {
        url: "/Home",
        alias: "Home",
        icon: "PacvueIconHome",
        menuId: 601,
        menuName: "Home",
        parentId: 0
      },
      {
        url: "/Product/ProductsDashBoard",
        alias: "Product",
        icon: "PacvueIconProductCenter",
        menuId: 602,
        menuName: "Product Center",
        parentId: 0
      },
      {
        url: "/ProductCenter/ProductDashboard",
        alias: "PC_PC",
        icon: null,
        menuId: 701,
        menuName: "Product Dashboard",
        parentId: 602
      },
      {
        url: "/ProductCenter/ProductListing",
        alias: "PC_SR",
        icon: null,
        menuId: 702,
        menuName: "Product Listing",
        parentId: 602
      },
      {
        url: "/ProductCenter/Order",
        alias: "PC_VR",
        icon: null,
        menuId: 703,
        menuName: "Order",
        parentId: 602
      },
      {
        url: "/Budget/BudgetDashboard",
        alias: "Budget",
        icon: "PacvueIconBudgetManager",
        menuId: 613,
        menuName: "Budget Manager",
        parentId: 0
      },
      {
        url: "/Advertising",
        alias: "Advertising",
        icon: "PacvueIconAdvertising",
        menuId: 603,
        menuName: "Advertising",
        parentId: 0
      },
      {
        url: "/Campaign/AdvertisingExplore",
        alias: "Ad_E",
        icon: null,
        menuId: 705,
        menuName: "Explorer",
        parentId: 603
      },
      {
        url: "/Campaign/Profile",
        alias: "Ad_Profile",
        icon: null,
        menuId: 706,
        menuName: "Profile",
        parentId: 603
      },
      {
        url: "/Campaign/CampaignTagging",
        alias: null,
        icon: null,
        menuId: 742,
        menuName: "Campaign Tagging",
        parentId: 707
      },
      {
        url: "/Campaign/AdgroupTagging",
        alias: null,
        icon: null,
        menuId: 743,
        menuName: "Adgroup Tagging",
        parentId: 707
      },
      {
        url: "/Campaign/KeywordTagging",
        alias: null,
        icon: null,
        menuId: 744,
        menuName: "Keyword Tagging",
        parentId: 707
      },
      {
        url: "/ASINTagging",
        alias: null,
        icon: null,
        menuId: 745,
        menuName: "Asin Tagging",
        parentId: 707
      },
      {
        url: "/Campaign/AmazonPortfolio",
        alias: "Ad_Portfolio",
        icon: null,
        menuId: 708,
        menuName: "Portfolio",
        parentId: 603
      },
      {
        url: "/Campaign/Campaign",
        alias: "Ad_Campaign",
        icon: null,
        menuId: 709,
        menuName: "Campaign",
        parentId: 603
      },
      {
        url: "/Campaign/AdGroup",
        alias: "Ad_Adgroup",
        icon: null,
        menuId: 710,
        menuName: "Adgroup",
        parentId: 603
      },
      {
        url: "/Campaign/Keyword",
        alias: "Ad_Targeting",
        icon: null,
        menuId: 711,
        menuName: "Targeting",
        parentId: 603
      },
      {
        url: "/Campaign/Asin",
        alias: "Ad_Asin",
        icon: null,
        menuId: 712,
        menuName: "Asin",
        parentId: 603
      },
      {
        url: "/Campaign/Query",
        alias: "Ad_ST",
        icon: null,
        menuId: 713,
        menuName: "Search Term",
        parentId: 603
      },
      {
        url: "/Campaign/NegativeKeyword",
        alias: "Ad_NT",
        icon: null,
        menuId: 714,
        menuName: "Negative Targeting",
        parentId: 603
      },
      {
        url: "/Campaign/BulkOperations",
        alias: "Ad_BO",
        icon: null,
        menuId: 715,
        menuName: "Bulk Operations",
        parentId: 603
      },
      {
        url: "/Report/Index",
        alias: "Report_DR",
        icon: null,
        menuId: 716,
        menuName: "Default Report",
        parentId: 604
      },
      {
        url: "/Report/MyReport",
        alias: "Report_My",
        icon: null,
        menuId: 717,
        menuName: "My Report",
        parentId: 604
      },
      {
        url: "/Report/MarketPlaceDashboard",
        alias: "MarketPlaceDashboard",
        icon: null,
        menuId: 718,
        menuName: "Marketplace Dashboard",
        parentId: 604
      },
      {
        url: "/Analytics/Index",
        alias: "Report_AD",
        icon: null,
        menuId: 719,
        menuName: "Analytics Dashboard",
        parentId: 604
      },
      {
        url: "/Research/KeywordsCopy",
        alias: "Opt_KR",
        icon: null,
        menuId: 720,
        menuName: "Keyword Research",
        parentId: 605
      },
      {
        url: "/Research/PATResearch",
        alias: "Opt_PR",
        icon: null,
        menuId: 721,
        menuName: "PAT Research",
        parentId: 605
      },
      {
        url: "/Optimization/AudienceResearch",
        alias: "Opt_AR",
        icon: null,
        menuId: 722,
        menuName: "Audience Research",
        parentId: 605
      },
      {
        url: "/Optimization/QueryAsinGraph",
        alias: "Opt_QAG",
        icon: null,
        menuId: 723,
        menuName: "Query-Asin Graph",
        parentId: 605
      },
      {
        url: "/Optimization/AiOptimization",
        alias: "Optimization",
        icon: "PacvueIconOptimization",
        menuId: 606,
        menuName: "Optimization",
        parentId: 0
      },
      {
        url: "/Optimization/Dayparting",
        alias: "Op_Dayparting",
        icon: null,
        menuId: 727,
        menuName: "Dayparting Scheduler",
        parentId: 606
      },
      {
        url: "/Optimization/Schedule",
        alias: "Opt_Budget",
        icon: null,
        menuId: 728,
        menuName: "Budget Scheduler",
        parentId: 606
      },
      {
        url: "/Rule/v2/#/index",
        alias: "Opt_RBO",
        icon: null,
        menuId: 729,
        menuName: "Rule",
        parentId: 606
      },
      {
        url: "/Optimization/AutomationAI",
        alias: "Opt_AI",
        icon: null,
        menuId: 730,
        menuName: "AI",
        parentId: 606
      },
      {
        url: "/AuditCenter",
        alias: "Ad_AuditCenter",
        icon: null,
        menuId: 731,
        menuName: "Paid Search Audit",
        parentId: 606
      },
      {
        url: "/Optimization/BidExplorer",
        alias: "BE",
        icon: null,
        menuId: 732,
        menuName: "Bid Explorer",
        parentId: 606
      },
      {
        url: "/DSP/Dashboard",
        alias: "DSP_Dashboard",
        icon: null,
        menuId: 733,
        menuName: "Dashboard",
        parentId: 607
      },
      {
        url: "/DSP/Advertiser",
        alias: "DSP_Advertiser",
        icon: null,
        menuId: 734,
        menuName: "Advertiser",
        parentId: 607
      },
      {
        url: "/DSP/Order",
        alias: "DSP_Order",
        icon: null,
        menuId: 735,
        menuName: "Order",
        parentId: 607
      },
      {
        url: "/DSP/Lineitems",
        alias: "DSP_LI",
        icon: null,
        menuId: 736,
        menuName: "Line Items",
        parentId: 607
      },
      {
        url: "/DSP/Creative",
        alias: "DSP_Creative",
        icon: null,
        menuId: 737,
        menuName: "Creative",
        parentId: 607
      },
      {
        url: "/DSP/Audience",
        alias: "DSP_Audience",
        icon: null,
        menuId: 738,
        menuName: "Audience",
        parentId: 607
      },
      {
        url: "/SDDashboard/Index",
        alias: "SDD",
        icon: "PacvueIconSponsoredDisplay",
        menuId: 608,
        menuName: "Sponsored Display",
        parentId: 0
      },
      {
        url: "/Analysis/Competitive",
        alias: "CA_Competitive",
        icon: null,
        menuId: 739,
        menuName: "Competitive",
        parentId: 609
      },
      {
        url: "/Analysis/ProductTracking",
        alias: "CA_PT",
        icon: null,
        menuId: 740,
        menuName: "Product Tracking",
        parentId: 609
      },
      {
        url: "/Analysis/BrandAudit",
        alias: "CA_BA",
        icon: null,
        menuId: 741,
        menuName: "Brand Audit",
        parentId: 609
      },
      {
        url: "/PriceTracker/Home",
        alias: "PT",
        icon: "PacvueIconPriceTracker",
        menuId: 610,
        menuName: "Price Tracker",
        parentId: 0
      },
      {
        url: "/CategoryIntelligence/SearchTerm",
        alias: null,
        icon: null,
        menuId: 746,
        menuName: "Search Term",
        parentId: 611
      },
      {
        url: "/CategoryIntelligence/Product",
        alias: null,
        icon: null,
        menuId: 747,
        menuName: "Product",
        parentId: 611
      },
      {
        url: "/CategoryIntelligence/BrandAndSales",
        alias: null,
        icon: null,
        menuId: 748,
        menuName: "Brand & Sales",
        parentId: 611
      },
      {
        url: "/Alert/CampaignEvent",
        alias: "Event",
        icon: "PacvueIconEvent",
        menuId: 612,
        menuName: "Event",
        parentId: 0
      }
    ]

    const homeMenu = findMenus(allMenus, 601)
    expect(homeMenu).toEqual([
      {
        url: "/Home",
        alias: "Home",
        icon: "PacvueIconHome",
        menuId: 601,
        menuName: "Home",
        parentId: 0
      }
    ])

    const defaultReportMenu = findMenus(allMenus, 716)
    // expect(defaultReportMenu).toEqual([
    //   {
    //     url: '/Home',
    //     alias: 'Home',
    //     icon: 'PacvueIconHome',
    //     menuId: 601,
    //     menuName: 'Home',
    //     parentId: 0
    //   },
    //   {
    //     url: '/Report/Index',
    //     alias: 'Report_DR',
    //     icon: null,
    //     menuId: 716,
    //     menuName: 'Default Report',
    //     parentId: 604
    //   }
    // ])
  })

  it("findMenuPathById", () => {
    const menus = [
      { alias: "Home", children: [], icon: "PacvueIconHome", menuId: 601, menuName: "Home", parentId: 0, url: "/Home" },
      {
        alias: "Product",
        children: [
          {
            alias: "PC_PC",
            children: [],
            icon: null,
            menuId: 701,
            menuName: "Product Dashboard",
            parentId: 602,
            url: "/ProductCenter/ProductDashboard"
          },
          {
            alias: "PC_SR",
            children: [],
            icon: null,
            menuId: 702,
            menuName: "Product Listing",
            parentId: 602,
            url: "/ProductCenter/ProductListing"
          },
          {
            alias: "PC_VR",
            children: [],
            icon: null,
            menuId: 703,
            menuName: "Order",
            parentId: 602,
            url: "/ProductCenter/Order"
          }
        ],
        icon: "PacvueIconProductCenter",
        menuId: 602,
        menuName: "Product Center",
        parentId: 0,
        url: "/Product/ProductsDashBoard"
      },
      {
        alias: "Budget",
        children: [],
        icon: "PacvueIconBudgetManager",
        menuId: 613,
        menuName: "Budget Manager",
        parentId: 0,
        url: "/Budget/BudgetDashboard"
      },
      {
        alias: "Advertising",
        children: [
          {
            alias: "Ad_E",
            children: [],
            icon: null,
            menuId: 705,
            menuName: "Explorer",
            parentId: 603,
            url: "/Campaign/AdvertisingExplore"
          },
          {
            alias: "Ad_Profile",
            children: [],
            icon: null,
            menuId: 706,
            menuName: "Profile",
            parentId: 603,
            url: "/Campaign/Profile"
          },
          {
            alias: "Ad_Tagging",
            children: [
              {
                alias: null,
                children: null,
                icon: null,
                menuId: 742,
                menuName: "Campaign Tagging",
                parentId: 707,
                url: "/Campaign/CampaignTagging"
              },
              {
                alias: null,
                children: null,
                icon: null,
                menuId: 743,
                menuName: "Adgroup Tagging",
                parentId: 707,
                url: "/Campaign/AdgroupTagging"
              },
              {
                alias: null,
                children: null,
                icon: null,
                menuId: 744,
                menuName: "Keyword Tagging",
                parentId: 707,
                url: "/Campaign/KeywordTagging"
              },
              {
                alias: null,
                children: null,
                icon: null,
                menuId: 745,
                menuName: "Asin Tagging",
                parentId: 707,
                url: "/ASINTagging"
              }
            ],
            icon: null,
            menuId: 707,
            menuName: "Tagging",
            parentId: 603,
            url: "/Campaign/CampaignTagging"
          },
          {
            alias: "Ad_Portfolio",
            children: [],
            icon: null,
            menuId: 708,
            menuName: "Portfolio",
            parentId: 603,
            url: "/Campaign/AmazonPortfolio"
          },
          {
            alias: "Ad_Campaign",
            children: [],
            icon: null,
            menuId: 709,
            menuName: "Campaign",
            parentId: 603,
            url: "/Campaign/Campaign"
          },
          {
            alias: "Ad_Adgroup",
            children: [],
            icon: null,
            menuId: 710,
            menuName: "Adgroup",
            parentId: 603,
            url: "/Campaign/AdGroup"
          },
          {
            alias: "Ad_Targeting",
            children: [],
            icon: null,
            menuId: 711,
            menuName: "Targeting",
            parentId: 603,
            url: "/Campaign/Keyword"
          },
          {
            alias: "Ad_Asin",
            children: [],
            icon: null,
            menuId: 712,
            menuName: "Asin",
            parentId: 603,
            url: "/Campaign/Asin"
          },
          {
            alias: "Ad_ST",
            children: [],
            icon: null,
            menuId: 713,
            menuName: "Search Term",
            parentId: 603,
            url: "/Campaign/Query"
          },
          {
            alias: "Ad_NT",
            children: [],
            icon: null,
            menuId: 714,
            menuName: "Negative Targeting",
            parentId: 603,
            url: "/Campaign/NegativeKeyword"
          },
          {
            alias: "Ad_BO",
            children: [],
            icon: null,
            menuId: 715,
            menuName: "Bulk Operations",
            parentId: 603,
            url: "/Campaign/BulkOperations"
          }
        ],
        icon: "PacvueIconAdvertising",
        menuId: 603,
        menuName: "Advertising",
        parentId: 0,
        url: "/Advertising"
      },
      {
        alias: "Report",
        children: [
          {
            alias: "Report_DR",
            children: [],
            icon: null,
            menuId: 716,
            menuName: "Default Report",
            parentId: 604,
            url: "/Report/Index"
          },
          {
            alias: "Report_My",
            children: [],
            icon: null,
            menuId: 717,
            menuName: "My Report",
            parentId: 604,
            url: "/Report/MyReport"
          },
          {
            alias: "MarketPlaceDashboard",
            children: [],
            icon: null,
            menuId: 718,
            menuName: "Marketplace Dashboard",
            parentId: 604,
            url: "/Report/MarketPlaceDashboard"
          },
          {
            alias: "Report_AD",
            children: [],
            icon: null,
            menuId: 719,
            menuName: "Analytics Dashboard",
            parentId: 604,
            url: "/Analytics/Index"
          }
        ],
        icon: "PacvueIconHomeReport",
        menuId: 604,
        menuName: "Report",
        parentId: 0,
        url: "/Report/Index"
      },
      {
        alias: "Research",
        children: [
          {
            alias: "Opt_KR",
            children: [],
            icon: null,
            menuId: 720,
            menuName: "Keyword Research",
            parentId: 605,
            url: "/Research/KeywordsCopy"
          },
          {
            alias: "Opt_PR",
            children: [],
            icon: null,
            menuId: 721,
            menuName: "PAT Research",
            parentId: 605,
            url: "/Research/PATResearch"
          },
          {
            alias: "Opt_AR",
            children: [],
            icon: null,
            menuId: 722,
            menuName: "Audience Research",
            parentId: 605,
            url: "/Optimization/AudienceResearch"
          },
          {
            alias: "Opt_QAG",
            children: [],
            icon: null,
            menuId: 723,
            menuName: "Query-Asin Graph",
            parentId: 605,
            url: "/Optimization/QueryAsinGraph"
          }
        ],
        icon: "PacvueIconResearch",
        menuId: 605,
        menuName: "Research",
        parentId: 0,
        url: "/Research/KeywordsCopy"
      },
      {
        alias: "Optimization",
        children: [
          {
            alias: "Op_Dayparting",
            children: [],
            icon: null,
            menuId: 727,
            menuName: "Dayparting Scheduler",
            parentId: 606,
            url: "/Optimization/Dayparting"
          },
          {
            alias: "Opt_Budget",
            children: [],
            icon: null,
            menuId: 728,
            menuName: "Budget Scheduler",
            parentId: 606,
            url: "/Optimization/Schedule"
          },
          {
            alias: "Opt_RBO",
            children: [],
            icon: null,
            menuId: 729,
            menuName: "Rule",
            parentId: 606,
            url: "/Rule/v2/#/index"
          },
          {
            alias: "Opt_AI",
            children: [],
            icon: null,
            menuId: 730,
            menuName: "AI",
            parentId: 606,
            url: "/Optimization/AutomationAI"
          },
          {
            alias: "Ad_AuditCenter",
            children: [],
            icon: null,
            menuId: 731,
            menuName: "Paid Search Audit",
            parentId: 606,
            url: "/AuditCenter"
          },
          {
            alias: "BE",
            children: [],
            icon: null,
            menuId: 732,
            menuName: "Bid Explorer",
            parentId: 606,
            url: "/Optimization/BidExplorer"
          }
        ],
        icon: "PacvueIconOptimization",
        menuId: 606,
        menuName: "Optimization",
        parentId: 0,
        url: "/Optimization/AiOptimization"
      },
      {
        alias: "DSP",
        children: [
          {
            alias: "DSP_Dashboard",
            children: [],
            icon: null,
            menuId: 733,
            menuName: "Dashboard",
            parentId: 607,
            url: "/DSP/Dashboard"
          },
          {
            alias: "DSP_Advertiser",
            children: [],
            icon: null,
            menuId: 734,
            menuName: "Advertiser",
            parentId: 607,
            url: "/DSP/Advertiser"
          },
          {
            alias: "DSP_Order",
            children: [],
            icon: null,
            menuId: 735,
            menuName: "Order",
            parentId: 607,
            url: "/DSP/Order"
          },
          {
            alias: "DSP_LI",
            children: [],
            icon: null,
            menuId: 736,
            menuName: "Line Items",
            parentId: 607,
            url: "/DSP/Lineitems"
          },
          {
            alias: "DSP_Creative",
            children: [],
            icon: null,
            menuId: 737,
            menuName: "Creative",
            parentId: 607,
            url: "/DSP/Creative"
          },
          {
            alias: "DSP_Audience",
            children: [],
            icon: null,
            menuId: 738,
            menuName: "Audience",
            parentId: 607,
            url: "/DSP/Audience"
          }
        ],
        icon: "PacvueIconSideBarDsp",
        menuId: 607,
        menuName: "DSP",
        parentId: 0,
        url: "/DSP/Dashboard"
      },
      {
        alias: "SDD",
        children: [],
        icon: "PacvueIconSponsoredDisplay",
        menuId: 608,
        menuName: "Sponsored Display",
        parentId: 0,
        url: "/SDDashboard/Index"
      },
      {
        alias: "CA",
        children: [
          {
            alias: "CA_Competitive",
            children: [],
            icon: null,
            menuId: 739,
            menuName: "Competitive",
            parentId: 609,
            url: "/Analysis/Competitive"
          },
          {
            alias: "CA_PT",
            children: [],
            icon: null,
            menuId: 740,
            menuName: "Product Tracking",
            parentId: 609,
            url: "/Analysis/ProductTracking"
          },
          {
            alias: "CA_BA",
            children: [],
            icon: null,
            menuId: 741,
            menuName: "Brand Audit",
            parentId: 609,
            url: "/Analysis/BrandAudit"
          }
        ],
        icon: "PacvueIconCompetitorAnalysis",
        menuId: 609,
        menuName: "Competitor Analysis",
        parentId: 0,
        url: "/Analysis/Competitive"
      },
      {
        alias: "PT",
        children: [],
        icon: "PacvueIconPriceTracker",
        menuId: 610,
        menuName: "Price Tracker",
        parentId: 0,
        url: "/PriceTracker/Home"
      },
      {
        alias: "CI",
        children: [
          {
            alias: null,
            children: [],
            icon: null,
            menuId: 746,
            menuName: "Search Term",
            parentId: 611,
            url: "/CategoryIntelligence/SearchTerm"
          },
          {
            alias: null,
            children: [],
            icon: null,
            menuId: 747,
            menuName: "Product",
            parentId: 611,
            url: "/CategoryIntelligence/Product"
          },
          {
            alias: null,
            children: [],
            icon: null,
            menuId: 748,
            menuName: "Brand & Sales",
            parentId: 611,
            url: "/CategoryIntelligence/BrandAndSales"
          }
        ],
        icon: "PacvueIconCategoryIntelligence",
        menuId: 611,
        menuName: "Category Intelligence",
        parentId: 0,
        url: "/CategoryIntelligence/SearchTerm"
      },
      {
        alias: "Event",
        children: [],
        icon: "PacvueIconEvent",
        menuId: 612,
        menuName: "Event",
        parentId: 0,
        url: "/Event#/Event"
      }
    ]
    const productDashboardMenus = findMenuPathById(menus, 701)
    expect(productDashboardMenus).toEqual([
      {
        alias: "Product",
        icon: "PacvueIconProductCenter",
        menuId: 602,
        menuName: "Product Center",
        parentId: 0,
        url: "/Product/ProductsDashBoard"
      },
      {
        alias: "PC_PC",
        icon: null,
        menuId: 701,
        menuName: "Product Dashboard",
        parentId: 602,
        url: "/ProductCenter/ProductDashboard"
      }
    ])
  })
}

export { findRootMenu, isLeaf, menu2url, _menu2url, path2root, findMenuPath, flatTree, findMenuPathById }
