import request from "@pacvue/frame/request"
var { VITE_APP_EventURL, VITE_APP_Download, VITE_APP_AnomalyAlert, VITE_APP_RULEURL, VITE_APP_EventMetaUrl } = import.meta.env
import { pacvueToken } from "@pacvue/utils"
const productline = localStorage.getItem("productline")
const VITE_APP_APIREPORT = productline === "amazon" ? import.meta.env.VITE_APP_APIREPORT : ""
// message
export function getMessages(data) {
  return request({
    url: `${VITE_APP_EventURL}notification/getMessages`,
    data,
    method: "post"
  })
}
export function showMessageType(data) {
  return request({
    url: `${VITE_APP_EventURL}notification/showMessageType`,
    data,
    method: "get"
  })
}
export function markOneAsRead(data = {}) {
  return request({
    url: `${VITE_APP_EventURL}notification/markOneAsRead`,
    method: "POST",
    params: data
  })
}
export function markAllAsRead(data = {}) {
  return request({
    url: `${VITE_APP_EventURL}notification/markAllAsRead`,
    method: "post",
    data
  })
}

// download
export function download(downloadUrl) {
  return request({
    url: `${downloadUrl}`,
    method: "get",
    responseType: "blob"
  })
}
export function listTask(data = {}) {
  return request({
    url: `${VITE_APP_Download}/listTask`,
    method: "post",
    data
  })
}
export function retryTask(data = {}) {
  return request({
    url: `${VITE_APP_Download}/retryTask`,
    method: "post",
    data
  })
}

// anomaly alert
export function getAlertInfo(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getAlertInfo`,
    method: "post",
    data
  })
}
export function getProfilePermissionAlert(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getProfilePermissionAlert`,
    method: "post",
    data
  })
}
export function getAsinStatusAlert(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getAsinStatusAlert`,
    method: "post",
    data
  })
}
export function getAsinOutOfStockAlert(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getAsinOutOfStockAlert`,
    method: "post",
    data
  })
}
export function campaignStatusAlert(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/campaignStatusAlert`,
    method: "post",
    data
  })
}
export function getAdAlert(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getAdAlert`,
    method: "post",
    data
  })
}
export function getRuleAlertDetail(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/history/getRuleAlertDetail`,
    method: "post",
    data
  })
}
export function getIdsByAsin(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getIdsByAsin`,
    method: "post",
    data
  })
}
export function getCampaignStatusProfile(data = {}) {
  return request({
    url: `${VITE_APP_EventMetaUrl}/filter/profiles`,
    method: "post",
    data
  })
}
export function setIgnore(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/skip`,
    method: "post",
    data
  })
}
export function exportAlertInfo(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/export/exportAlertInfo`,
    method: "post",
    responseType: "blob",
    data
  })
}
// manage alert
export function getAlertSetting(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/getAlertSetting`,
    method: "post",
    data
  })
}
export function changeManageAlertStatus(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/status`,
    method: "post",
    data
  })
}
export function ManageAlertSetting(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/alert/setting`,
    method: "post",
    data
  })
}
// ignore list
export function getSkipList(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/skipList`,
    method: "post",
    data
  })
}
export function ReEnable(data = {}) {
  return request({
    url: `${VITE_APP_AnomalyAlert}/reEnable`,
    method: "post",
    data
  })
}
// amazon
export function getProductEligibilityCheck(params) {
  return request({
    url: `${VITE_APP_APIREPORT}/api/DefaultReport/v3/GetProductEligibilityCheck`,
    method: "get",
    params,
    cancelToken: true
  })
}
// fetch 获取未读消息
export function getHasMessages(data) {
  return new Promise(async (resolve, reject) => {
    try {
      const { getTokens } = pacvueToken
      const str = getTokens()
      const url = `${VITE_APP_EventURL}notification/getMessages`

      const controller = new AbortController()
      const signal = controller.signal

      const response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(data),
        headers: {
          productline: productline == "dsp" ? "amazondsp" : productline,
          Authorization: "Bearer " + str,
          "Content-Type": "application/json"
        },
        signal: signal
      })
      if (!response.ok) {
        reject([])
        throw new Error("Network response was not ok " + response.statusText)
      }
      const reader = response.body.getReader()
      const decoder = new TextDecoder("utf-8")
      const chunkSize = 1024 // 取1KB数据
      let buffer = ""
      let whileDone = false
      while (!whileDone) {
        const { done, value } = await reader.read()
        if (done) {
          if (buffer.length > 0) {
            const result = processChunk(buffer)
            if (result) {
              whileDone = true
              resolve(result)
            }
          }
          break
        }
        buffer += decoder.decode(value, { stream: true })

        while (buffer.length >= chunkSize) {
          const chunk = buffer.slice(0, chunkSize)
          buffer = buffer.slice(chunkSize)
          const result = processChunk(chunk)
          if (result) {
            whileDone = true
            resolve(result)
            controller.abort() // 终止请求
            return
          }
        }
      }
    } catch (error) {
      reject(error)
    }
  })
}
function processChunk(chunk) {
  // console.log("Received chunk:", chunk)
  try {
    const partialData = chunk.match(/"loadingRead":\[(.*?)\]/)
    if (partialData) {
      const loadingRead = JSON.parse(`[${partialData[1]}]`)
      return loadingRead
    }
  } catch (error) {
    console.error("Error parsing chunk:", error)
  }
  return []
}

//rule type
export function getRuleTypes(params) {
  return request({
    url: `${VITE_APP_RULEURL}config/ruleTypes`,
    method: "get",
    params
  })
}
// getOwners
export function getOwners(clientId) {
  return request({
    url: `${VITE_APP_RULEURL}definition/transferableOwnerList`,
    cancelToken: true,
    method: "get"
  })
}
