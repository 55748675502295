const realTimePages = [
  {
    alias: "Ad_Campaign",
    menu: "Campaign"
  },
  {
    alias: "Ad_Adgroup",
    menu: "Adgroup"
  },
  {
    alias: "Ad_Targeting",
    menu: "Targeting"
  },
  {
    alias: "Ad_Targeting",
    menu: "Target"
  },
  {
    alias: "Campaign Tagging",
    menu: "CampaignTag"
  },
  {
    alias: "Ad_Asin",
    menu: "Asin"
  },
  {
    alias: "AdGroupSub",
    menu: "Adgroup"
  },
  {
    alias: "Adgroup Tagging",
    menu: "AdgroupTag"
  }
  // {
  //   alias: "Keyword Tagging",
  //   menu: "KeywordTag"
  // },
  // {
  //   alias: "Asin Tagging",
  //   menu: "ASINTag"
  // }
]
// alias://后端接口中的alias
// menu://前端Layout组件中的menu

export function getRealTimePart(props = ["alias"]) {
  return realTimePages.map((item) => {
    var propKey = props[0]
    if (props.length == 1) {
      return item[propKey]
    }
    return item
  })
}
