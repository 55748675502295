import { useUserStore } from "@/store"
import { commonJS } from "@pacvue/utils"
import { unref } from "vue"
/**
 * @function {获取当前菜单配置}
 * @param {string} path - 菜单路径
 */
export const getCurrentMenuMap = (path, unify_name) => {
  path = path || location.pathname
  try {
    // 若存在统一名称值,则返回空菜单数据,即不验证权限不获取menuId直接存取
    if (unify_name) {
      return {}
    }
    const menuMap = JSON.parse(localStorage.getItem(`MenuMap`)) || {}
    let currentMenuMap = menuMap[path]
    return currentMenuMap
  } catch (error) {
    console.error("getCurrentMenuMap error", error)
  }
}
/**
 * @function {判断权限}
 * @param {string} currentMenuMap - 菜单数据对象
 */
export const hasPermission = (currentMenuMap) => {
  // 0是没有权限,1的状态为显示不可编辑,//2就是有所有权限
  // undefined 为不存在权限验证的菜单
  return currentMenuMap.permissionType === undefined || currentMenuMap.permissionType == 2
}
export function usePacvuePermission({ userRole, menuAlias, pathname } = {}) {
  userRole = unref(userRole)
  if (!userRole) {
    const userStore = useUserStore()
    userRole = userStore.user?.userRole || "Admin"
  }
  let menuPermissonType = 2 //2是存在编辑权限,1是只读权限
  if (userRole !== "Read") {
    //用户存在编辑权限,则需要验证菜单权限
    if (menuAlias) {
      menuPermissonType = commonJS.getPermission(menuAlias)
    } else {
      menuPermissonType = getActivePagePermission({ pathname })
    }
  } else {
    menuPermissonType = 1
  }
  return menuPermissonType == 2 ? "Edit" : "Read"
}
export function getActivePagePermission(options = {}) {
  let localPath = location.pathname
  let permissionType = 2
  try {
    const { path = localPath, unify_name = false } = options
    let currentMenuMap = getCurrentMenuMap(path, unify_name) || {}
    // 存在权限
    if (!hasPermission(currentMenuMap)) {
      permissionType = 1
    }
  } catch (ex) {
    permissionType = 2
  }
  return permissionType
}
