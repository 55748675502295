<template>
  <div class="pacvue-recommendation-menuGuide-wrap">
    <div class="pacvue-recommendation-menuGuide-header">
      <img src="./images/left.png" class="menuGuide-left" />
      <img src="./images/center.png" class="menuGuide-center" />
      <img src="./images/right.png" class="menuGuide-right" />
    </div>
    <div class="pacvue-recommendation-menuGuide-body">
      <div v-for="item in descrList" :key="item.title" :class="{ 'pt-3': true }">
        <div class="text-[var(--color-title--)] font-semibold flex items-center">
          <img src="./images/star.png" class="w-6" />
          <span class="ml-2">{{ item.title }}</span>
        </div>
        <div class="text-[var(--color-text--)]">{{ item.content }}</div>
      </div>
    </div>
  </div>
</template>
<script setup>
let descrList = [
  {
    title: $t("Feature Upgrade Announcement"),
    content: $t("We’re excited to announce that Paid Search Audit has been fully upgraded to Recommendation!")
  },
  {
    title: $t("Brand-New UI Experience"),
    hasStar: true,
    content: $t("A more intuitive interface that helps you easily understand various types of recommendations and optimize your ads more effectively.")
  },
  {
    title: $t("Amazon Recommendations Added"),
    hasStar: true,
    content: $t("amskey3345")
  }
]
</script>
<style scoped lang="scss">
@import "./index.scss";
</style>
<style lang="scss">
.pacvue-menu-guide-step {
  background: var(--pac-theme-color);
  width: 46px;
  border-radius: 12px;
  display: flex;
  padding-left: 0px !important;
  justify-content: center;
  margin: auto;
  padding-right: 0px !important;
  .el-icon {
    color: white !important;
  }
}
.pacvue-menuGuideContent-wrapper {
  .driver-popover-footer {
    margin-bottom: 12px;
  }
}
#driver-highlighted-element-stage {
  &.border-radius-12 {
    border-radius: 12px;
  }
}
</style>
