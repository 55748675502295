export default {
  router: [
    {
      path: "/login/index",
      redirect: "/login"
    },
    {
      path: "/login",
      name: "login",
      component: () => import("../src/view/Login/sign.vue"),
      meta: {}
    },
    {
      path: "/loginoauth/:name",
      name: "SSO Backward Redirect",
      component: () => import("../src/view/Login/loginWithSSO.vue"),
      meta: {}
    },
    {
      path: "/login/:id",
      name: "SSO Platform Select",
      component: () => import("../src/view/Login/loginWithSSO.vue"),
      meta: {}
    },
    {
      path: "/Auth",
      name: "Auth",
      component: () => import("../src/view/Login/Auth.vue"),
      meta: {}
    },
    {
      path: "/ActiveEmail/:secretKey",
      name: "ActiveEmail",
      props: true,
      component: () => import("../src/view/Login/ActiveEmail.vue"),
      meta: {}
    },
    {
      path: "/OktaProfile",
      name: "OktaProfile",
      component: () => import("../src/okta/components/OktaProfile.vue")
    },
    {
      path: "/callback",
      name: "OktaCallback",
      component: () => import("../src/okta/components/Callback.vue")
    },
    {
      path: "/callback/:extraParam",
      name: "OktaCallbackIntermediate",
      component: () => import("../src/okta/components/Callback.vue")
    }
  ]
}
