import axios from "axios"
import request from "@pacvue/frame/request"
const reqCancelMap = {}
export function requestData(options, { cacheKey, isAutoCancel = true, cacheKeyPrefix = "" } = {}) {
  const CancelToken = axios.CancelToken
  const source = CancelToken.source()
  var req = request({
    ...options,
    cancelToken: source.token,
    _cancel: source.cancel
  })
  if (cacheKey && isAutoCancel) {
    let realCacheKey = `${cacheKeyPrefix ? cacheKeyPrefix + "_" : ""}${cacheKey}`
    let prevCancel = reqCancelMap[realCacheKey]
    typeof prevCancel == "function" && prevCancel()
    reqCancelMap[realCacheKey] = source.cancel
    return new Promise((resolve, reject) => {
      req
        .then((res) => resolve(res))
        .catch((ex) => reject(ex))
        .finally(() => {
          delete reqCancelMap[realCacheKey]
        })
    })
  }
  req.cancel = source.cancel
  return req
}
