const productLineHandler = ({ commonStoreContext, productLine } = {}) => {
  if (productLine === "bol" && commonStoreContext?.bolVersion == 2) {
    productLine = "bolv2"
  }
  if (productLine === "chewy" && commonStoreContext?.chewyVersion == 2) {
    productLine = "chewyv2"
  }
  return productLine
}

const hexToRgba = (hex, alpha) => {
  hex = hex.replace(/^#/, "")
  let r = 0,
    g = 0,
    b = 0
  if (hex.length === 3) {
    // If shorthand hex code, e.g., #03F
    r = parseInt(hex[0] + hex[0], 16)
    g = parseInt(hex[1] + hex[1], 16)
    b = parseInt(hex[2] + hex[2], 16)
  } else if (hex.length === 6) {
    // If full hex code, e.g., #0033FF
    r = parseInt(hex.substring(0, 2), 16)
    g = parseInt(hex.substring(2, 4), 16)
    b = parseInt(hex.substring(4, 6), 16)
  }
  return `rgba(${r}, ${g}, ${b}, ${alpha})`
}

export { productLineHandler, hexToRgba }
