import { computed } from "vue"
import * as allCopilotApis from "./api"
import { capitalize } from "lodash-es"

const productLine = window.productline || localStorage.getItem("productline")

const nameArr = [
  "KeywordText",
  "keywordText",
  "ASIN",
  "Asin",
  "asin",
  "query",
  "targetText",
  "keywordText",
  "ProfileName",
  "CampaignName",
  "campaignName",
  "CampaignType",
  "AdgroupName",
  "adgroupName",
  "CampaignTagName",
  "AdGroupTagName",
  "adgroupTagName",
  "AdgroupTagName",
  "AsinTagName",
  "ASINTagName",
  "KeywordTagName",
  "PortfolioName",
  // SOV、PD Center 相关
  "Brand",
  "brand",
  "VendorGroup",
  "VendorGroupName",
  "SOVGroup",
  // dsp campaign manager
  "advertiserName",
  "orderName",
  "lineItemName",
  "creativeName",
  "Segment",
  "TagName",
  "orderTagName",
  "lineItemTagName",
  "creativeTagName",
  "asinConversionType",
  // commerce common
  "Account",
  "Market",
  // commerce sales
  "Brand",
  "AmazonBrand",
  "Category",
  "AmazonCategory",
  "ParentASIN",
  "ProductTag",
  "VendorCode",
  "ProductAvailability",
  "ReleaseDate",
  // commerce promotion
  "PromotionEvent",
  "Promotion",
  "PromotionType",
  "PromotionState",
  "PromotionStatus",
  "StartDate",
  "EndDate",
  // commerce coupon
  "VendorCode",
  "CouponID",
  "CouponName",
  "CampaignID",
  "CampaignBudgetType",
  "DiscountType",
  "WebsiteMessage",
  "StartTime",
  "EndTime",
  "UPC",
  "EAN",
  "ModelNo",
  // commerce order
  "OrderID",
  "FulFillmentChannel",
  "SalesChannel",
  "ShipService",
  "ItemStatus",
  "PurchaseDate",
  "LastUpdateDate",
  "EstDeliveryDate",
  "RequestReviewsStatus"
]

// 后端 Field 映射到 => 前端 Table 列标题
const metricI18nMapAll = computed(() => ({
  amazon: {
    // Title 相关
    AccountName: $t("DSPkey3254"),
    ProfileName: $t("key597"),
    CampaignName: $t("key174"),
    adgroupName: $t("key62"),
    AdgroupName: $t("key62"),
    PortfolioName: $t("Portfolio Name"),
    CampaignTagName: $t("key1757"),
    AdGroupTagName: $t("key768"),
    KeywordTagName: $t("key768"),
    AsinTagName: $t("ASIN Tag Name"),
    ASINTagName: $t("ASIN Tag Name"),
    targetText: $t("Product"),
    CampaignType: $t("ai103"),
    KeywordText: $t("event108"),
    Brand: $t("key138"),
    VendorGroup: $t("amskey514"),
    // Advertising 相关
    SaleUnits: $t("Sale Units"),
    NewToBrandOrders: $t("NTB-Orders"),
    NewToBrandSales: $t("NTB-Sales"),
    NewToBrandUnitsOrdered: $t("NTB-Units"),
    ViewImpression: $t("amskey1506"),
    BrandedSearch: $t("amskey1513"),
    SearchTermImpressionShare: $t("key2725"),
    Conversion: $t("Orders"),
    Order: $t("Orders"),
    // SOV 相关
    PaidSOV: $t("key551"),
    OrganicSOV: $t("key533"),
    SBSOV: $t("key668"),
    SBProductSOV: $t("key667"),
    SpSOV: $t("key729"),
    SBVSOV: $t("sovkey121"),
    ShareOfShelf: $t("sovkey449"),
    SpTopSOV: $t("sovkey486"),
    SpOtherSOV: $t("sovkey487"),
    Freq: $t("Ads Frequency"),
    Top5TotalSOV: $t("Top {0} SOV", ["5"]),
    Top10TotalSOV: $t("Top {0} SOV", ["10"]),
    Top15TotalSOV: $t("Top {0} SOV", ["15"]),
    Top5OrganicSOV: $t("Top {0} Organic SOV", ["5"]),
    Top10OrganicSOV: $t("Top {0} Organic SOV", ["10"]),
    Top15OrganicSOV: $t("Top {0} Organic SOV", ["15"]),
    TopPageRate: $t("Page {0} Frequency", ["1"]),
    TopThreeRate: $t("Top {0} Frequency", ["3"]),
    TopSixRate: $t("Top {0} Frequency", ["6"]),
    Position1Rate: $t("Position {0} Frequency", ["1"]),
    Position2Rate: $t("Position {0} Frequency", ["2"]),
    Position3Rate: $t("Position {0} Frequency", ["3"]),
    Position4Rate: $t("Position {0} Frequency", ["4"]),
    Position5Rate: $t("Position {0} Frequency", ["5"]),
    Position6Rate: $t("Position {0} Frequency", ["6"]),
    AvgPosition: $t("key130"),
    AvgOrganicPosition: $t("key128"),
    AvgPaidPosition: $t("key129"),
    // PD Center 相关指标
    AdSales: $t("Ad Sales"),
    AdSpend: $t("Ad Spend"),
    AdOrders: $t("amskey547"),
    OrganicRevenue: $t("Organic Revenue"),
    TACOS: $t("event558"),
    TROAS: $t("key2819"),
    TotalMediaSales: $t("key1920"),
    TotalRevenue: $t("Total Revenue"),
    AvgRating: $t("Average Rating"),
    Reviews: $t("Reviews"),
    GlanceViews: $t("key348")
  },
  dsp: {
    // DSP Campaign Manager - Title 相关
    advertiserName: $t("Advertiser"),
    orderName: $t("Order"),
    lineItemName: $t("Line Item"),
    creativeName: $t("Creative"),
    Segment: $t("Name"),
    TagName: $t("key768"),
    OrderTagName: $t("Order Tag"),
    LineItemTagName: $t("Line Item Tag"),
    CreativeTagName: $t("Creative Tag"),
    asinConversionType: $t("DSPkey2883"),
    // DSP Campaign Manager - Metric 相关
    TotalCost: $t("Total Cost"),
    Impressions: $t("amskey1855"),
    ClickThroughs: $t("key208"),
    CTR: $t("CTR"),
    ATC: $t("ATC"),
    Purchases: $t("Purchases"),
    DPV: $t("DPV"),
    CVR: $t("CVR"),
    eCPM: $t("eCPM"),
    eCPC: $t("eCPC"),
    DPVR: $t("DPVR"),
    eCPDPV: $t("eCPDPV"),
    ATCR: $t("ATCR"),
    eCPP: $t("eCPP"),
    eCPATC: $t("eCPATC"),
    UnitsSold: $t("DSPkey2072"),
    ProductSales: $t("DSPkey1531"),
    TotalSales: $t("DSPkey2049"),
    TotalROAS: $t("Total ROAS"),
    TotalPurchases: $t("DSPkey2045"),
    TotalATC: $t("DSPkey2027"),
    TotalDPV: $t("DSPkey2030"),
    NTBPurchases: $t("DSPkey1267"),
    NTBSales: $t("DSPkey3529"),
    NTBROAS: $t("NTB ROAS")
  },
  walmart: {
    // Title 相关
    ProfileName: $t("key597"),
    CampaignName: $t("key174"),
    CampaignType: $t("ai103"),
    AdgroupName: $t("key62"),
    CampaignTagName: $t("key768"),
    KeywordTagName: $t("key768"),
    AsinTagName: $t("key768"),
    ASINTagName: $t("key768"),
    ASIN: $t("Item"),
    asin: $t("Item"),
    KeywordText: $t("event108"),
    keywordText: $t("event108"),
    SOVGroup: $t("SOV Group"),
    brand: $t("Brand"),
    Query: $t("Query"),
    query: $t("Query"),
    // Advertising 指标相关
    CPA: $t("CPA"),
    CVR: $t("CVR"),
    ROAS: $t("ROAS"),
    ACOS: $t("ACOS"),
    AdSKUROAS: $t("Ad SKU ROAS"),
    AdSKUACOS: $t("Ad SKU ACOS"),
    Sales: $t("Sales"),
    Orders: $t("Orders"),
    Conversion: $t("Orders"),
    SaleUnits: $t("Sale Units"),
    NewToBrandOrders: $t("NTB-Orders"),
    NewToBrandSales: $t("NTB-Sales"),
    NewToBrandUnitsOrdered: $t("NTB-Units"),
    OnlineCPA: $t("Online CPA"),
    OnlineCVR: $t("Online CVR"),
    OnlineROAS: $t("Online ROAS"),
    OnlineACOS: $t("Online ACOS"),
    OnlineAdSKUACOS: $t("Online Ad SKU ACOS"),
    OnlineAdSKUROAS: $t("Online Ad SKU ROAS"),
    OnlineSales: $t("Online Sales"),
    OnlineOrders: $t("Online Orders"),
    OnlineSaleUnits: $t("Online Sale Units"),
    // SOV 相关
    price: $t("Price"),
    paidSOV: $t("key551"),
    totalSOV: $t("sovkey449"),
    spPaidSOV: $t("SP SOV"),
    spTopSOV: $t("Top of Search SP SOV"),
    spOtherSOV: $t("Rest of Search SP SOV"),
    sbPaidSOV: $t("key668"),
    sbvPaidSOV: $t("SV SOV"),
    organicSOV: $t("Organic SOV"),
    adsFrequency: $t("Ads Frequency"),
    top5TotalSOV: $t("Top {0} SOV", ["5"]),
    top10TotalSOV: $t("Top {0} SOV", ["10"]),
    top15TotalSOV: $t("Top {0} SOV", ["15"]),
    top5OrganicSOV: $t("Top {0} Organic SOV", ["5"]),
    top10OrganicSOV: $t("Top {0} Organic SOV", ["10"]),
    top15OrganicSOV: $t("Top {0} Organic SOV", ["15"]),
    top5SpSOV: $t("Top {0} SP SOV", ["5"]),
    top10SpSOV: $t("Top {0} SP SOV", ["10"]),
    top15SpSOV: $t("Top {0} SP SOV", ["15"]),
    Position1Rate: $t("Position {0} Frequency", ["1"]),
    Position2Rate: $t("Position {0} Frequency", ["2"]),
    Position3Rate: $t("Position {0} Frequency", ["3"]),
    Position4Rate: $t("Position {0} Frequency", ["4"]),
    Position5Rate: $t("Position {0} Frequency", ["5"]),
    Position6Rate: $t("Position {0} Frequency", ["6"]),
    pageOneRate: $t("Page {0} Frequency", ["1"]),
    topOneRate: $t("Top {0} Frequency", ["1"]),
    topThreeRate: $t("Top {0} Frequency", ["3"]),
    topSixRate: $t("Top {0} Frequency", ["6"]),
    minPrice: $t("Price"),
    avgPosition: $t("key130"),
    avgOrganicPosition: $t("key128"),
    avgPaidPosition: $t("key129"),
    // PD Center 相关指标
    AdSales: $t("Ad Sales"),
    AdSpend: $t("Ad Spend"),
    AdSaleUnits: $t("key32"),
    TACOS: $t("event558"),
    TROAS: $t("key2819"),
    TotalRevenue: $t("Total Revenue"),
    TotalOrders: $t("key812"),
    TotalSaleUnits: $t("key1767"),
    OrganicRevenue: $t("Organic Revenue"),
    AverageRating: $t("Average Rating"),
    ReviewsCount: $t("Reviews"),
    OverallQualityScore: $t("key543")
  },
  commerce: {
    Sales: {
      Account: $t("CommerceCommon.Account"),
      Market: $t("CommerceAmazon.common.market"),
      VendorCode: $t("CommerceAmazon.common.vendorCode"),
      Brand: $t("Brand"),
      AmazonBrand: $t("CommerceAmazon.sales.amazonBrand"),
      Category: $t("Category"),
      AmazonCategory: $t("CommerceAmazon.sales.amazonCategory"),
      ASIN: $t("CommerceAmazon.sales.asin"),
      ParentASIN: $t("CommerceAmazon.sales.parentAsin"),
      ProductTag: $t("CommerceAmazon.sales.tagName"),
      ProductAvailability: $t("CommerceAmazon.common.productAvailability"),
      OrderedRevenue: $t("CommerceAmazon.sales.orderedRevenue"),
      OrderedUnits: $t("CommerceAmazon.sales.orderedUnits"),
      ShippedRevenue: $t("CommerceAmazon.sales.shippedRevenue"),
      ShippedUnits: $t("CommerceAmazon.common.shippedUnits"),
      COGS: $t("CommerceAmazon.sales.cogs"),
      ShippedCOGS: $t("CommerceAmazon.sales.shippedCogs"),
      CustomerReturns: $t("CommerceAmazon.sales.customerReturns"),
      AvgSalePrice: $t("CommerceAmazon.sales.avgSalePrice"),
      RetailerGPM: $t("CommerceAmazon.common.gpmPercent"),
      NetPPM: $t("CommerceAmazon.common.netPPM"),
      NetPPP: $t("CommerceAmazon.sales.netPpp"),
      GlanceViews: $t("CommerceAmazon.sales.glanceViews"),
      ConversionRate: $t("CommerceAmazon.sales.cvr"),
      BuyboxOwnership: $t("CommerceAmazon.home.common.buyboxOwnership"),
      OrganicPV: $t("CommerceAmazon.home.common.organicPVs"),
      ReleaseDate: $t("CommerceAmazon.catalog.releaseDate"),
      ListPrice: $t("CommerceAmazon.common.listPrice"),
      ASINCount: $t("CommerceAmazon.common.asins")
    },
    RealTimeSales: {
      Account: $t("CommerceCommon.Account"),
      Market: $t("CommerceAmazon.common.market"),
      VendorCode: $t("CommerceAmazon.common.vendorCode"),
      Brand: $t("Brand"),
      AmazonBrand: $t("CommerceAmazon.sales.amazonBrand"),
      Category: $t("Category"),
      AmazonCategory: $t("CommerceAmazon.sales.amazonCategory"),
      ASIN: $t("CommerceAmazon.sales.asin"),
      ParentASIN: $t("CommerceAmazon.sales.parentAsin"),
      ProductTag: $t("CommerceAmazon.sales.tagName"),
      ProductAvailability: $t("CommerceAmazon.common.productAvailability"),
      OrderedRevenue: $t("CommerceAmazon.sales.orderedRevenue"),
      OrderedUnits: $t("CommerceAmazon.sales.orderedUnits"),
      ShippedRevenue: $t("CommerceAmazon.sales.shippedRevenue"),
      ShippedUnits: $t("CommerceAmazon.common.shippedUnits"),
      COGS: $t("CommerceAmazon.sales.cogs"),
      ShippedCOGS: $t("CommerceAmazon.sales.shippedCogs"),
      CustomerReturns: $t("CommerceAmazon.sales.customerReturns"),
      AvgSalePrice: $t("CommerceAmazon.sales.avgSalePrice"),
      RetailerGPM: $t("CommerceAmazon.common.gpmPercent"),
      NetPPM: $t("CommerceAmazon.common.netPPM"),
      NetPPP: $t("CommerceAmazon.sales.netPpp"),
      GlanceViews: $t("CommerceAmazon.sales.glanceViews"),
      ConversionRate: $t("CommerceAmazon.sales.cvr"),
      BuyboxOwnership: $t("CommerceAmazon.home.common.buyboxOwnership"),
      OrganicPV: $t("CommerceAmazon.home.common.organicPVs"),
      ReleaseDate: $t("CommerceAmazon.catalog.releaseDate"),
      ListPrice: $t("CommerceAmazon.common.listPrice"),
      ASINCount: $t("CommerceAmazon.common.asins"),
      PromotionEvent: $t("CommerceAmazon.sales.promotionEvent")
    },
    Promotion: {
      Account: $t("CommerceCommon.Account"),
      Market: $t("CommerceAmazon.common.market"),
      Promotion: $t("CommerceAmazon.promotion.ColumnLabel.PromotionName"),
      PromotionType: $t("CommerceAmazon.promotion.ColumnLabel.PromotionType"),
      PromotionStatus: $t("CommerceAmazon.promotion.PromotionStatus"),
      PromotionState: $t("Promotion State"),
      StartDate: $t("CommerceAmazon.promotion.ColumnLabel.StartDate"),
      EndDate: $t("CommerceAmazon.promotion.ColumnLabel.EndDate"),
      ProductCount: $t("CommerceAmazon.promotion.ColumnLabel.Products"),
      TotalCoOpDeductions: $t("CommerceAmazon.TotalCoOpDeductions"),
      GlanceViews: $t("CommerceAmazon.promotion.ColumnLabel.PGlanceViews"),
      DailyGlanceViews: $t("CommerceAmazon.promotion.ColumnLabel.PDailyGlanceViews"),
      AvgDailyGlanceViews: $t("CommerceAmazon.promotion.ColumnLabel.AvgDailyGlanceViews"),
      GlanceViewLift: $t("CommerceAmazon.promotion.ColumnLabel.PGlanceViewLift"),
      ConversionRate: $t("CommerceAmazon.promotion.ColumnLabel.PConversionRate"),
      AvgDailyConversionRate: $t("CommerceAmazon.promotion.ColumnLabel.AvgDailyConversionRate"),
      ConversionRateLift: $t("CommerceAmazon.promotion.ColumnLabel.PConversionRateLift"),
      Spend: $t("CommerceAmazon.promotion.ColumnLabel.PSpend"),
      Revenue: $t("CommerceAmazon.promotion.ColumnLabel.PRevenue"),
      Tag: $t("CommerceAmazon.promotion.ColumnLabel.Tag"),
      Discount: $t("CommerceAmazon.promotion.ColumnLabel.Discount"),
      UnitsSold: $t("CommerceAmazon.common.pUnitsSold"),
      DailyUnitsSold: $t("CommerceAmazon.common.pDailyUnitsSold"),
      UnitsSoldLift: $t("CommerceAmazon.merchandising.pUnitSoldLift"),
      AvgDailyUnitsSold: $t("CommerceAmazon.promotion.ColumnLabel.AvgDailyUnitSold"),
      DailyRevenue: $t("CommerceAmazon.promotion.ColumnLabel.PDailyRevenue"),
      RevenueLift: $t("CommerceAmazon.promotion.ColumnLabel.PRevenueLift"),
      AvgDailyRevenue: $t("CommerceAmazon.promotion.ColumnLabel.AvgDailyRevenue"),
      TotalUnits: $t("CommerceAmazon.promotion.ColumnLabel.TotalUnits")
    },
    Coupon: {
      VendorCode: $t("CommerceAmazon.coupon.vendorCode"),
      CouponID: $t("CommerceAmazon.coupon.couponID"),
      CouponName: $t("CommerceAmazon.coupon.couponName"),
      CampaignID: $t("CommerceAmazon.coupon.campaignID"),
      CampaignName: $t("CommerceAmazon.coupon.campaignName"),
      CampaignBudgetType: $t("CommerceAmazon.coupon.campaignBudgetType"),
      Discount: $t("CommerceAmazon.coupon.discount"),
      DiscountPercent: $t("CommerceAmazon.coupon.discount"),
      DiscountType: $t("CommerceAmazon.coupon.discountType"),
      StartTime: $t("CommerceAmazon.coupon.startTime"),
      EndTime: $t("CommerceAmazon.coupon.endTime"),
      Title: $t("CommerceAmazon.common.title"),
      ASIN: $t("CommerceAmazon.common.asin"),
      WebsiteMessage: $t("CommerceAmazon.coupon.websiteMessage"),
      Budget: $t("CommerceAmazon.coupon.budget"),
      BudgetUsed: $t("CommerceAmazon.coupon.budgetUsed"),
      BudgetSpent: $t("CommerceAmazon.coupon.budgetSpent"),
      Clips: $t("CommerceAmazon.coupon.clips"),
      Redemptions: $t("CommerceAmazon.coupon.redemptions"), // redeem => redemption
      RedemptionsRate: $t("CommerceAmazon.coupon.redemptionsRate"),
      Units: $t("CommerceAmazon.coupon.units"),
      Sales: $t("CommerceAmazon.common.sales"),
      UPC: $t("CommerceAmazon.common.SearchTypeLabel.UPC"),
      EAN: $t("CommerceAmazon.common.SearchTypeLabel.EAN"),
      ModelNo: $t("CommerceAmazon.common.modelNo")
    },
    // commerce v1 暂时不做
    Order: {
      Account: $t("CommerceCommon.Account"),
      Market: $t("CommerceAmazon.common.market"),
      Price: $t("CommerceAmazon.common.price"),
      OrderID: $t("CommerceAmazon.order.amazonOrderId"),
      QuantityOrdered: $t("CommerceAmazon.order.quantityOrdered"),
      ItemStatus: $t("CommerceAmazon.order.itemStatus"),
      OffsitePromotionFee: $t("CommerceAmazon.order.offSitePromotionFeeBtn"),
      FulFillmentChannel: $t("CommerceAmazon.order.fulfillmentChannel"),
      SalesChannel: $t("CommerceAmazon.order.salesChannel"),
      OrderTotal: $t("CommerceAmazon.order.orderTotal"),
      ShipService: $t("CommerceAmazon.order.shipService"),
      PurchaseDate: $t("CommerceAmazon.order.purchaseDateStr"),
      LastUpdateDate: $t("CommerceAmazon.order.lastUpdatedDateStr"),
      EstDeliveryDate: $t("CommerceAmazon.order.estDeliveryDate"),
      RequestReviewsStatus: $t("CommerceAmazon.order.statusOfRequestReviewsOrFeedback")
    }
  }
}))

const unifyMetrics = (metric) => {
  const metricLowerCase = metric.toLowerCase()
  const otherMap = {
    AOV: "sales",
    ASP: "sales",
    SearchTermImpressionShare: "impressionshare",
    OverallQualityScore: "click",
    BrandedSearch: "brandedSearch",
    ...(productLine === "dsp" ? { CVR: "dpvr" } : {})
  }
  const commerceMap = {
    ShippedUnits: "shipped units",
    BuyboxOwnership: "buybox ownership",
    ConversionRate: "percent",
    AvgDailyConversionRate: "percent",
    ConversionRateLift: "numberFixed:2",
    CustomerReturns: "customer returns",
    NetPPM: "net ppm%",
    OrderedUnits: "ordered units",
    RetailerGPM: "gpm%",
    OrganicPV: "number",
    RedemptionsRate: "percent",
    BudgetUsed: "percent",
    Clips: "number",
    Redemptions: "number",
    ProductCount: "number",
    ASINCount: "number",
    Tag: "number",
    UnitsSold: "number",
    DailyUnitsSold: "number",
    AvgDailyUnitsSold: "number",
    UnitsSoldLift: "numberFixed:2",
    TotalUnits: "number",
    TotalCoOpDeductions: "number",
    GlanceViews: "glance views",
    DailyGlanceViews: "glance views",
    AvgDailyGlanceViews: "glance views",
    GlanceViewLift: "numberFixed:2",
    RevenueLift: "numberFixed:2"
  }
  // Ads 通用
  if (!productLine.startsWith("commerce")) {
    if (metricLowerCase.includes("acos")) {
      return "acos"
    } else if (metricLowerCase.includes("roas")) {
      return "roas"
    }
  }
  // DSP, Commerce 以外通用
  if (!["commerce", "dsp"].includes(productLine)) {
    if (metricLowerCase.includes("rate") || metricLowerCase.includes("cvr") || metricLowerCase.includes("freq")) {
      return "percent" // 比率、频率 %
    } else if (metricLowerCase.includes("sales") || metricLowerCase.includes("revenue") || metricLowerCase.includes("cost")) {
      return "sales" // 成本、收入、销售额
    } else if (metricLowerCase.includes("purchase") || metricLowerCase.includes("orders") || metricLowerCase.includes("saleunit")) {
      return "orders" // 购买行为 => 订单
    } else {
      return otherMap[metric]
    }
  } else if (productLine === "commerce") {
    return commerceMap[metric]
  } else {
    return otherMap[metric]
  }
}

const displayFieldSpFiltering = ({ jsonParam, fields, fieldName, userId } = {}) => {
  if (productLine === "amazon") {
    if (jsonParam.groupBy === "placement" && fieldName.startsWith("NewToBrand")) {
      return false
    }
  } else if (productLine === "walmart") {
    // walmart user 若没有线下绩效, 则只展示不带 Online 的 (因为没有区分了)
    const isOnlinePerfOnly = localStorage.getItem(`${userId}_SettingStore`) == "false" || localStorage.getItem(`${userId}_SettingStore`) == "null"
    const isDuplicatedOnlineMetric = fieldName === "OnlineOrders" || (fieldName.startsWith("Online") && fields.includes(fieldName.replace("Online", "")))
    if (isOnlinePerfOnly && isDuplicatedOnlineMetric) {
      return false
    }
  } else if (productLine === "dsp") {
    if (fieldName === "entityName") {
      return false
    }
  }
  return true
}

const insightPage = {
  Campaign: "Campaign",
  CampaignAdGroup: "Adgroup",
  Keyword: "Keyword",
  PAT: "PAT",
  CampaignAsin: "ASIN",
  CampaignProfile: "Profile",
  CampaignTagging: "CampaignTag",
  KeywordTagging: "KeywordTag",
  AsinTag: "AsinTag",
  "Product Listing": "ProductCenter-Asin",
  "SOV Brand": "SOV"
}

const apiDataQueryNavMapAll = {
  amazon: {
    "/api/Profile/ProfilePerformance": {
      destRoutePath: "/Campaign/Profile",
      destFilterKeys: ["profileIds"]
    },
    "/api/Campaign/CampaignPerformance": {
      destRoutePath: "/Campaign/Campaign",
      destFilterKeys: ["campaignIds"]
    },
    "/api/Campaign/CampaignChangeRatioPerformance": {
      destRoutePath: "/Campaign/Campaign",
      destFilterKeys: ["campaignIds"]
    },
    "/api/Portfolio/PortfolioPerformance": {
      destRoutePath: "/Campaign/AmazonPortfolio",
      destFilterKeys: ["portfolioIds"]
    },
    "/api/Adgroup/AdgroupPerformance": {
      destRoutePath: "/Campaign/AdGroup",
      destFilterKeys: ["adgroupIds"]
    },
    "/api/Tagging/CampaignTagPerformance": {
      destRoutePath: "/Campaign/CampaignTagging",
      destFilterKeys: ["taggingIds", "campaignTagIds"],
      allTagApi: allCopilotApis[`getCampaignTagsAll${capitalize(productLine)}`],
      needClientId: true,
      searchFn: (searchId, tagRes) => tagRes.find((tag) => tag.id == searchId)?.children?.map((item) => item.id) ?? []
    },
    "/api/Tagging/AdgroupTagPerformance": {
      destRoutePath: "/Campaign/AdgroupTagging",
      destFilterKeys: ["taggingIds", "adgroupTagIds"],
      allTagApi: allCopilotApis[`getAdGroupTagsAll${capitalize(productLine)}`],
      searchFn: (searchId, tagRes) => tagRes.find((tag) => tag.id == searchId)?.child?.map((item) => item.id) ?? []
    },
    "/api/Tagging/KeywordTagPerformance": {
      destRoutePath: "/Campaign/KeywordTagging",
      destFilterKeys: ["keywordTagIds"]
      // allTagApi: allCopilotApis[`getKeywordTagsAll${capitalize(productLine)}`]
    },
    "/api/Tagging/AsinTagPerformance": {
      destRoutePath: "/Campaign/ASINTagging",
      destFilterKeys: ["taggingIds", "ASINTagIds"],
      allTagApi: allCopilotApis[`getAsinTagsAll${capitalize(productLine)}`],
      searchFn: (searchId, tagRes) => tagRes.find((tag) => tag.tagId == searchId)?.subAsinTags?.map((item) => item.id) ?? []
    },
    "/api/Keyword/KeywordPerformance": {
      textFieldName: "KeywordText",
      destRoutePath: "/Campaign/Keyword",
      destFilterKeys: ["keywordTexts"],
      extraPayload: { keywordOperand: "Equal" }
    },
    "/api/Keyword/KeywordChangeRatioPerformance": {
      textFieldName: "KeywordText",
      destRoutePath: "/Campaign/Keyword",
      destFilterKeys: ["keywordTexts"],
      extraPayload: { keywordOperand: "Equal" }
    },
    "/api/SearchTerm/PATQueryPerformance": {
      textFieldName: "targetText",
      destRoutePath: "/Campaign/Keyword",
      destFilterKeys: ["targetTexts"],
      extraPayload: { targetOperand: "LikeOr", kindType: "PAT" }
    },
    "/api/Asin/AsinPerformance": {
      textFieldName: "ASIN",
      destRoutePath: "/Campaign/Asin",
      destFilterKeys: ["asins"]
    },
    "/api/Asin/AsinChangeRatioPerformance": {
      textFieldName: "ASIN",
      destRoutePath: "/Campaign/Asin",
      destFilterKeys: ["asins"]
    },
    "/api/SearchTerm/KeywordQueryPerformance": {
      textFieldName: "query",
      destRoutePath: "/Campaign/Query",
      destFilterKeys: ["query"],
      extraPayload: { url: "target", type: "PAT", keywordOperand: "Equal" }
    }
  },
  dsp: {
    "/api/Copilot/Advertiser/AdvertiserPerformance": {
      destRoutePath: "/CampaignManager/A",
      requiredIdFields: ["advertiser"]
    },
    "/api/Copilot/Order/OrderPerformance": {
      destRoutePath: "/CampaignManager/Orders",
      requiredIdFields: ["order", "advertiser"]
    },
    "/api/Copilot/Order/OrderChangeRatioPerformance": {
      destRoutePath: "/CampaignManager/Orders",
      requiredIdFields: ["order", "advertiser"]
    },
    "/api/Copilot/LineItem/LineItemPerformance": {
      destRoutePath: "/CampaignManager/LineItems",
      requiredIdFields: ["order", "lineItem", "advertiser"]
    },
    "/api/Copilot/LineItem/LineItemChangeRatioPerformance": {
      destRoutePath: "/CampaignManager/LineItems",
      requiredIdFields: ["order", "lineItem", "advertiser"]
    },
    "/api/Copilot/Creative/CreativePerformance": {
      destRoutePath: "/CampaignManager/Creative",
      requiredIdFields: ["creative", "advertiser"]
    },
    "/api/Copilot/Creative/CreativeChangeRatioPerformance": {
      destRoutePath: "/CampaignManager/Creative",
      requiredIdFields: ["creative", "advertiser"]
    },
    "/api/Copilot/Audience/AudiencePerformance": {
      textFieldName: "targetText",
      destRoutePath: "/CampaignManager/Audience",
      destFilterKey: "audienceSearch",
      requiredIdFields: ["advertiser"],
      requiredNameFields: ["audienceSearch"]
    },
    "/api/Copilot/Product/ProductPerformance": {
      textFieldName: "ASIN",
      destRoutePath: "/CampaignManager/Product",
      requiredIdFields: ["advertiser"],
      requiredNameFields: ["asins"]
    },
    "/api/Copilot/Product/ProductChangeRatioPerformance": {
      textFieldName: "ASIN",
      destRoutePath: "/CampaignManager/Product",
      requiredIdFields: ["advertiser"],
      requiredNameFields: ["asins"]
    },
    "/api/Copilot/Tagging/OrderTagPerformance": {
      destRoutePath: "/Tag/OrderTag",
      requiredIdFields: ["orderTag", "advertiser"],
      allTagApi: allCopilotApis[`getOrderTagsAll${capitalize(productLine)}`],
      allTagApiParams: { forUserLevel: false, profileIds: [] },
      searchFn: (searchId, tagRes) => tagRes.find((tag) => tag.tagId == searchId)?.subTags?.map((item) => item.tagId) ?? []
    },
    "/api/Copilot/Tagging/OrderTagChangeRatioPerformance": {
      destRoutePath: "/Tag/OrderTag",
      requiredIdFields: ["orderTag", "advertiser"],
      allTagApi: allCopilotApis[`getOrderTagsAll${capitalize(productLine)}`],
      allTagApiParams: { forUserLevel: false, profileIds: [] },
      searchFn: (searchId, tagRes) => tagRes.find((tag) => tag.tagId == searchId)?.subTags?.map((item) => item.tagId) ?? []
    },
    "/api/Copilot/Tagging/LineItemTagPerformance": {
      destRoutePath: "/Tag/LineTag",
      requiredIdFields: ["lineitemTag", "advertiser"],
      allTagApi: allCopilotApis[`getLineItemTagsAll${capitalize(productLine)}`],
      allTagApiParams: { forUserLevel: false, profileIds: [] },
      searchFn: (searchId, tagRes) => tagRes.find((tag) => tag.tagId == searchId)?.subTags?.map((item) => item.tagId) ?? []
    },
    "/api/Copilot/Tagging/CreativeTagPerformance": {
      destRoutePath: "/Tag/CreativeTag",
      requiredIdFields: ["creativeTag", "advertiser"],
      allTagApi: allCopilotApis[`getCreativeTagsAll${capitalize(productLine)}`],
      allTagApiParams: { forUserLevel: false, profileIds: [] },
      searchFn: (searchId, tagRes) => tagRes.find((tag) => tag.tagId == searchId)?.subTags?.map((item) => item.tagId) ?? []
    }
  },
  walmart: {
    "/api/Copilot/Profile/ProfilePerformance": {
      destRoutePath: "/Campaign/Profile",
      destFilterKeys: ["profileIds"]
    },
    "/api/Copilot/Campaign/CampaignPerformance": {
      destRoutePath: "/Campaign/Campaign",
      destFilterKeys: ["campaignIds"]
    },
    "/api/Copilot/Campaign/CampaignChangeRatioPerformance": {
      destRoutePath: "/Campaign/Campaign",
      destFilterKeys: ["campaignIds"]
    },
    "/api/Copilot/Adgroup/AdgroupPerformance": {
      destRoutePath: "/Campaign/AdGroup",
      destFilterKeys: ["adgroupIds"],
      destFilterCascadeMap: { CampaignId: "campaignIds" }
    },
    "/api/Copilot/Keyword/KeywordPerformance": {
      textFieldName: "KeywordText",
      destRoutePath: "/Campaign/Keyword",
      destFilterKeys: ["keywordTexts"],
      extraPayload: { keywordOperand: "Equal" }
    },
    "/api/Copilot/Keyword/KeywordChangeRatioPerformance": {
      textFieldName: "KeywordText",
      destRoutePath: "/Campaign/Keyword",
      destFilterKeys: ["keywordTexts"],
      extraPayload: { keywordOperand: "Equal" }
    },
    "/api/Copilot/SearchTerm/KeywordQueryPerformance": {
      textFieldName: "query",
      destRoutePath: "/Campaign/Query",
      destFilterKeys: ["query"],
      extraPayload: { queryOperand: "Equal" }
    },
    "/api/Copilot/Asin/AsinPerformance": {
      textFieldName: "ASIN",
      destRoutePath: "/Campaign/Item",
      destFilterKeys: ["itemName"],
      extraPayload: { __isStandard__: true }
    },
    "/api/Copilot/Asin/AsinChangeRatioPerformance": {
      textFieldName: "ASIN",
      destRoutePath: "/Campaign/Item",
      destFilterKeys: ["itemName"],
      extraPayload: { __isStandard__: true }
    },
    "/api/Copilot/CampaignTag/CampaignTagPerformance": {
      destRoutePath: "/Campaign/CampaignTagging",
      destFilterKeys: ["taggingIds", "campaignTagIds"],
      allTagApi: allCopilotApis[`getCampaignTagsAll${capitalize(productLine)}`],
      searchFn: (searchId, tagRes) => tagRes.find((tag) => tag.tagId == searchId)?.subTags?.map((item) => item.tagId) ?? []
    },
    "/api/Copilot/CampaignTag/CampaignTagChangeRatioPerformance": {
      destRoutePath: "/Campaign/CampaignTagging",
      destFilterKeys: ["taggingIds", "campaignTagIds"],
      allTagApi: allCopilotApis[`getCampaignTagsAll${capitalize(productLine)}`],
      searchFn: (searchId, tagRes) => tagRes.find((tag) => tag.tagId == searchId)?.subTags?.map((item) => item.tagId) ?? []
    },
    "/api/Copilot/KeywordTag/KeywordTagPerformance": {
      destRoutePath: "/Campaign/KeywordTagging",
      destFilterKeys: ["taggingIds", "keywordTagIds"],
      allTagApi: allCopilotApis[`getKeywordTagsAll${capitalize(productLine)}`],
      searchFn: (searchId, tagRes) => tagRes.find((tag) => tag.tagId == searchId)?.subTags?.map((item) => item.tagId) ?? []
    },
    "/api/Copilot/AsinTag/AsinTagPerformance": {
      destRoutePath: "/Campaign/ItemTagging",
      destFilterKeys: ["taggingIds", "itemTagIds"],
      allTagApi: allCopilotApis[`getItemTagsAll${capitalize(productLine)}`],
      extraPayload: { __isStandard__: true },
      searchFn: (searchId, tagRes) => tagRes.find((tag) => tag.tagId == searchId)?.subTags?.map((item) => item.tagId) ?? []
    }
  },
  commerce: {}
}

const colors = ["#9B88FA", "#FFB268", "#75BDFF", "#FF9B9E", "#8FE4F0", "#6585F3", "#C2F372", "#6ADFA7", "#299AFF", "#DE9DF4", "#28DAC6", "#F0E360", "#F5AAD7", "#97E7D3", "#FFDF6F", "#00CFE8"]

const insightTypeFieldMap = {
  amazon: {
    Profile: "Profile name",
    Portfolio: "Portfolio name",
    "Campaign Tag": "Campaign Tag name",
    "ASIN Tag": "ASIN Tag name",
    "Keyword Tag": "Keyword Tag name",
    "AdGroup Tag": "AdGroup Tag name",
    Keyword: "Keyword",
    "Product Target": "Product Target",
    Adgroup: "Adgroup name",
    ASIN: "ASIN number",
    SOV: "SOV Group",
    "Product Center - Brand": "Brand name",
    "Product Center - ASIN": "ASIN ID"
  }
}

const dataQueryGroupByMap = computed(() => ({
  amazon: {
    placement: {
      backForeEndMapper: {
        "other on-amazon": $t("amskey460"),
        "top of search on-amazon": $t("amskey458"),
        "detail page on-amazon": $t("amskey459"),
        "off amazon": $t("amskey2920")
      }
    }
    // campaignType: { cascadedCols: ["CampaignType"] }
  }
}))

// Record<String, Map<Array<RegExp>, Array<String>>>
const QUESTIONS_PRESETS_KB = computed(() => ({
  amazon: new Map([
    [{ path: /^\/Home.*/, name: "Home" }, [$t("What does each widget represent in Home module?"), $t("How to customize the widgets in Home module?"), $t("How to share the Home page link?")]],
    [{ path: /^\/ProductCenter\/ProductDashboard.*/, name: "ProductDashboard" }, [$t("What is Product Center used for?")]],
    [{ path: /^\/ProductCenter\/ProductListing.*/, name: "Product Listing" }, [$t("What is Product Center used for?"), $t("How to view the performance of a specific ASIN in Product Center?")]],
    [{ path: /^\/Budget\/BudgetDashboard.*/, name: "Budget Manager" }, [$t("amskey3264"), $t("amskey3265"), $t("amskey3266")]],
    [{ path: /^\/Campaign\/CampaignTagging.*/, name: "CampaignTagging" }, [$t("What is a campaign tag used for?"), $t("How to add campaigns to a campaign tag?")]],
    [{ path: /^\/Campaign\/KeywordTagging.*/, name: "KeywordTagging" }, [$t("What is a keyword tag used for?"), $t("How to add keywords to a keyword tag?")]],
    [{ path: /^\/Campaign\/ASINTagging.*/, name: "AsinTag" }, [$t("What is an ASIN tag used for?"), $t("How to add ASIN to an ASIN tag?")]],
    [
      { path: /^\/Campaign\/Campaign.*/, name: "Campaign" },
      [
        $t("How to create sp campaign?"),
        $t("What is Sponsored Products Super Wizard?"),
        $t("How to use automation function to optimize a campaign?"),
        $t("How to view the performance of a specific campaign?")
      ]
    ],
    [{ path: /^\/Campaign\/Keyword.*/, name: "Targeting" }, [$t("How to add keywords to an adgroup?"), $t("How to view the performance of a specific keyword?")]],
    [{ path: /^\/Campaign\/Asin.*/, name: "CampaignAsin" }, [$t("How to add ASIN to an adgroup in ASIN module?"), $t("How to view the performance of a specific ASIN?")]],
    [{ path: /^\/Campaign\/Query.*/, name: "CampaignSearchTerm" }, [$t("How to add queries as keywords to an adgroup?"), $t("How to view the performance of a specific query?")]],
    [{ path: /^\/Report\/Index.*/i, name: "Default Report" }, [$t("DSPkey3503")]],
    [{ path: /^\/Report\/MyReport.*/, name: "My Report" }, [$t("DSPkey3504")]],
    [{ path: /^\/Research\/KeywordsCopy.*/, name: "Keyword Research" }, [$t("What is Keyword Research used for?"), $t("What is the difference between three research types?")]],
    [{ path: /^\/Optimization\/Rule.*/, name: "Rule" }, [$t("amskey3267"), $t("amskey3268"), $t("amskey3269"), $t("amskey3270"), $t("amskey3271")]],
    [{ path: /^\/Optimization\/Dayparting.*/, name: "Dayparting Scheduler" }, [$t("amskey3272"), $t("amskey3273"), $t("amskey3274")]],
    [{ path: /^\/Optimization\/Schedule.*/, name: "Budget Schedule" }, [$t("amskey3275"), $t("amskey3276"), $t("amskey3277")]],
    [{ path: /^\/Optimization\/AutomationAI.*/, name: "Campaign AI" }, [$t("amskey3278"), $t("amskey3279"), $t("amskey3280"), $t("amskey3281"), $t("amskey3282")]],
    [{ path: /^\/Optimization\/ASINAI.*/, name: "Product AI" }, [$t("amskey3283"), $t("amskey3284"), $t("amskey3285"), $t("amskey3286")]],
    [{ path: /^\/AuditCenter.*/, name: "Paid Search Audit" }, [$t("amskey3287"), $t("amskey3288"), $t("amskey3289")]],
    [{ path: /^\/Optimization\/LiveMomentum.*/, name: "War Room" }, [$t("amskey3290"), $t("amskey3291"), $t("amskey3292")]],
    [{ path: /^\/Optimization\/BidExplorer.*/, name: "Bidexplorer" }, [$t("amskey3293"), $t("amskey3294"), $t("amskey3295")]],
    [{ path: /^\/Analysis.*/, name: "Competitive" }, [$t("What is Competitor Analysis used for?"), $t("How to monitor keywords?"), $t("How to view the sov result of a monitored keyword?")]],
    [{ path: /^\/CategoryIntelligence\/SearchTerm.*/, name: "SearchTerm" }, [$t("What is Category Intelligence used for?")]],
    [{ path: /^\/CategoryIntelligence\/Product.*/, name: "Product" }, [$t("What is Category Intelligence used for?")]],
    [{ path: /^\/CategoryIntelligence\/BrandAndSales.*/, name: "BrandAndSales" }, [$t("What is Category Intelligence used for?")]]
    // [{ path: /^\/Permission\/UserManage.*/, name: "User Management" }, [$t("key3048"), $t("key3049")]]
  ]),
  dsp: new Map([
    [{ path: /^\/Dashboard.*/, name: "Dashboard" }, [$t("DSPkey3464"), $t("DSPkey3465")]],
    [{ path: /^\/Budget\/BudgetDashboard.*/, name: "Budget Manager" }, [$t("amskey3264"), $t("amskey3265"), $t("amskey3266")]],
    [{ path: /^\/CampaignManager\/Audience.*/, name: "Audience" }, [$t("DSPkey3484"), $t("DSPkey3485"), $t("DSPkey3486")]],
    [{ path: /^\/CampaignManager\/A.*/, name: "Advertiser" }, [$t("DSPkey3469"), $t("DSPkey3470")]],
    [{ path: /^\/CampaignManager\/Orders.*/, name: "Orders" }, [$t("DSPkey3471"), $t("DSPkey3472"), $t("DSPkey3473"), $t("DSPkey3474")]],
    [{ path: /^\/CampaignManager\/LineItems.*/, name: "LineItems" }, [$t("DSPkey3477"), $t("DSPkey3478")]],
    [{ path: /^\/CampaignManager\/Creative.*/, name: "Creative" }, [$t("DSPkey3479"), $t("DSPkey3480"), $t("DSPkey3481"), $t("DSPkey3482"), $t("DSPkey3483")]],
    [{ path: /^\/CampaignManager\/Product.*/, name: "Product" }, [$t("DSPkey3487"), $t("DSPkey3488"), $t("DSPkey3483"), $t("DSPkey3490")]],
    [{ path: /^\/CampaignManager\/OffAmazon.*/, name: "OffAmazon" }, [$t("DSPkey3491"), $t("DSPkey3492"), $t("DSPkey3493")]],
    [{ path: /^\/Tag\/OrderTag.*/, name: "OrderTag" }, [$t("DSPkey3494"), $t("DSPkey3495"), $t("DSPkey3496")]],
    [{ path: /^\/Tag\/LineTag.*/, name: "LineTag" }, [$t("DSPkey3497"), $t("DSPkey3498"), $t("DSPkey3499")]],
    [{ path: /^\/Tag\/CreativeTag.*/, name: "CreativeTag" }, [$t("DSPkey3500"), $t("DSPkey3501"), $t("DSPkey3502")]],
    [{ path: /^\/AudienceResearch.*/, name: "AudienceLibrary" }, [$t("DSPkey3505"), $t("DSPkey3506"), $t("DSPkey3507")]],
    [{ path: /^\/Research\/CreativeInsight.*/, name: "CreativeInsight" }, [$t("DSPkey3508")]],
    [{ path: /^\/Report\/DefaultReport.*/, name: "DefaultReport" }, [$t("DSPkey3503")]],
    [{ path: /^\/Report\/MyReport.*/, name: "MyReport" }, [$t("DSPkey3504")]],
    [{ path: /^\/Optimization\/Rule.*/, name: "Rule" }, [$t("amskey3267"), $t("amskey3268"), $t("amskey3269"), $t("amskey3270"), $t("amskey3271")]],
    [{ path: /^\/AI.*/, name: "AI" }, [$t("DSPkey3514"), $t("DSPkey3515"), $t("DSPkey3516"), $t("DSPkey3517")]],
    [{ path: /^\/Audit.*/, name: "AuditCenter" }, [$t("DSPkey3518"), $t("DSPkey3519")]]
  ]),
  walmart: new Map([
    [{ path: /^\/Home.*/, name: "Home" }, [$t("What does each widget represent in Home module?"), $t("How to customize the widgets in Home module?"), $t("How to share the Home page link?")]],
    [{ path: /^\/ProductCenter\/ProductDashboard.*/, name: "ProductDashboard" }, [$t("What is Product Center used for?")]],
    [
      { path: /^\/ProductCenter\/ProductListing.*/, name: "Product Listing" },
      [$t("What is Product Center used for?"), $t("How to view the performance of a specific item in Product Center?"), $t("How to add items to an adgroup in Product Center?")]
    ],
    [{ path: /^\/Budget\/BudgetDashboard.*/, name: "Budget Manager" }, [$t("amskey3264"), $t("amskey3265"), $t("amskey3266")]],
    [{ path: /^\/Campaign\/CampaignTagging.*/, name: "CampaignTagging" }, [$t("What is a campaign tag used for?"), $t("How to add campaigns to a campaign tag?")]],
    [{ path: /^\/Campaign\/KeywordTagging.*/, name: "KeywordTagging" }, [$t("What is a keyword tag used for?"), $t("How to add keywords to a keyword tag?")]],
    [{ path: /^\/Campaign\/ItemTagging.*/, name: "ItemTagging" }, [$t("What is an item tag used for?"), $t("How to add items to an item tag?")]],
    [
      { path: /^\/Campaign\/Campaign.*/, name: "Campaign" },
      [$t("How to create sp campaign?"), $t("How to use automation function to optimize a campaign?"), $t("How to view the performance of a specific campaign?")]
    ],
    [{ path: /^\/Campaign\/Keyword.*/, name: "Targeting" }, [$t("How to add keywords to an adgroup?"), $t("How to view the performance of a specific keyword?")]],
    [{ path: /^\/Campaign\/Item.*/, name: "AdvertisingItem" }, [$t("How to add items to an adgroup?"), $t("How to view the performance of a specific item in Item module?")]],
    [{ path: /^\/Campaign\/Query.*/, name: "AdvertisingSearchTerm" }, [$t("How to add queries as keywords to an adgroup?"), $t("How to view the performance of a specific query?")]],
    // [{ path: /^\/Campaign\/BulkOperations.*/, name: "AdvertisingBulkOperations" }, [$t("key3028"), $t("key3029"), $t("key3030")]],
    [{ path: /^\/Report\/index.*/i, name: "Default Report" }, [$t("DSPkey3503")]],
    [{ path: /^\/Report\/MyReport/, name: "My Report" }, [$t("DSPkey3504")]],
    [{ path: /^\/Research\/KeywordsCopy.*/, name: "Keyword Research" }, [$t("What is Keyword Research used for?"), $t("What is the difference between three research types?")]],
    [{ path: /^\/Research\/ImprShare.*/, name: "Impr Share" }, [$t("What is Impr. Share used for?")]],
    [{ path: /^\/Optimization\/QueryAsinGraph.*/, name: "Query-Item Graph" }, [$t("What is Query-Item Graph used for?")]],
    [{ path: /^\/Optimization\/Rule.*/, name: "Rule" }, [$t("amskey3267"), $t("amskey3268"), $t("amskey3269"), $t("amskey3270"), $t("amskey3271")]],
    [{ path: /^\/Optimization\/Dayparting.*/, name: "Dayparting Scheduler" }, [$t("amskey3272"), $t("amskey3273"), $t("amskey3274")]],
    [{ path: /^\/Optimization\/budgetschedule.*/, name: "Budget Scheduler" }, [$t("amskey3275"), $t("amskey3276"), $t("amskey3277")]],
    [{ path: /^\/Optimization\/AutomationAI.*/, name: "Campaign AI" }, [$t("amskey3278"), $t("amskey3279"), $t("amskey3280"), $t("amskey3281"), $t("amskey3282")]],
    [{ path: /^\/Optimization\/ASINAI.*/, name: "Product AI" }, [$t("amskey3283"), $t("amskey3284"), $t("amskey3285"), $t("amskey3286")]],
    [{ path: /^\/AuditCenter.*/, name: "Paid Search Audit" }, [$t("amskey3287"), $t("amskey3288"), $t("amskey3289")]],
    [{ path: /^\/Optimization\/LiveMomentum.*/, name: "War Room" }, [$t("amskey3290"), $t("amskey3291"), $t("amskey3292")]],
    [{ path: /^\/Optimization\/BidExplorer.*/, name: "Bidexplorer" }, [$t("amskey3293"), $t("amskey3294"), $t("amskey3295")]],
    [{ path: /^\/Analysis.*/, name: "Competitive" }, [$t("What is Competitor Analysis used for?"), $t("How to monitor keywords?"), $t("How to view the sov result of a monitored keyword?")]],
    [{ path: /^\/CategoryIntelligence\/SearchTerm.*/, name: "SearchTerm" }, [$t("What is Category Intelligence used for?")]],
    [{ path: /^\/CategoryIntelligence\/Product.*/, name: "Product" }, [$t("What is Category Intelligence used for?")]],
    [{ path: /^\/CategoryIntelligence\/BrandAndSales.*/, name: "BrandAndSales" }, [$t("What is Category Intelligence used for?")]]
    // [{ path: /^\/Account\/MyAccount.*/, name: "My Account" }, [$t("key3045"), $t("key3046"), $t("key3047")]],
    // [{ path: /^\/Permission\/UserManage.*/, name: "User Management" }, [$t("key3048"), $t("key3049")]],
    // [{ path: /\/ShareLink/, name: "Share Link" }, [$t("key3050"), $t("key3051")]]
  ]),
  commerce: new Map([
    [
      { path: /^\/Business\/Sales.*/, name: "Sales" },
      (moduleName) => {
        return {
          welcomeMsg: $t("Commerce.Amazon.Copilot.FeatureIntro", [moduleName]),
          q: [$t("What can I do on {0} page?", [moduleName])]
        }
      }
    ],
    [
      { path: /^\/Business\/RealTimeSales.*/i, name: "Real Time Sales" },
      (moduleName) => {
        return {
          welcomeMsg: $t("Commerce.Amazon.Copilot.FeatureIntro", [moduleName]),
          q: [$t("What can I do on {0} page?", [moduleName])]
        }
      }
    ],
    [
      { path: /^\/Business\/Promotion.*/i, name: "Promotion" },
      (moduleName) => {
        return {
          welcomeMsg: $t("Commerce.Amazon.Copilot.FeatureIntro", [moduleName]),
          q: [$t("What can I do on {0} page?", [moduleName])]
        }
      }
    ],
    [
      { path: /^\/Business\/Coupon.*/i, name: "Coupon" },
      (moduleName) => {
        return {
          welcomeMsg: $t("Commerce.Amazon.Copilot.FeatureIntro", [moduleName]),
          q: [$t("What can I do on {0} page?", [moduleName])]
        }
      }
    ]
    // v1 版本先不上 Order, 故注释掉
    // [
    //   { path: /^\/Business\/Order.*/, name: "Order" },
    //   (moduleName) => {
    //     return {
    //       welcomeMsg: $t("Commerce.Amazon.Copilot.FeatureIntro", [moduleName]),
    //       q: [$t("What can I do on {0} page?", [moduleName])]
    //     }
    //   }
    // ]
  ])
}))
// 除了固定问答预设外，还有一些特殊的问答预设，如 Commerce 平台使用routePath来区分不同的问答预设
const QUESTIONS_PRESETS_DQ = computed(() => ({
  amazon: [
    $t("Show me top 10 campaigns of last month ordered by sales."),
    $t("Show me Campaign performance group by campaign type of the last 14 days."),
    $t("Show me top 10 campaigns group by placement.")
  ],
  walmart: [
    $t("Show me top 10 campaigns of last month ordered by sales."),
    $t("Show me top 10 adgroup performance trend of the last 14 days."),
    $t("What are the top 5 keywords by online sales in the last 30 days?")
  ],
  dsp: [
    $t("Show me top 10 orders for last month ordered by total ROAS."),
    $t("Show me Line Items ordered by purchase from high to low for the last month."),
    $t("What are the top 5 Line Items by Total Cost in the last 30 days?")
  ],
  commerce: new Map([
    [
      { path: /^\/Business\/Sales.*/, name: "Sales" },
      (moduleName) => {
        return {
          welcomeMsg: $t("Commerce.Amazon.Copilot.DataQuery", [moduleName]),
          q: [
            $t("Commerce.Amazon.Copilot.DataQuery.RcmQst1")
            //  $t("Commerce.Amazon.Copilot.DataQuery.RcmQst2")
          ]
        }
      }
    ],
    [
      { path: /^\/Business\/RealTimeSales.*/i, name: "Real Time Sales" },
      (moduleName) => {
        return {
          welcomeMsg: $t("Commerce.Amazon.Copilot.DataQuery", [moduleName]),
          q: [$t("Commerce.Amazon.Copilot.DataQuery.RcmQst3")]
        }
      }
    ],
    [
      { path: /^\/Business\/Promotion.*/i, name: "Promotion" },
      (moduleName) => {
        return {
          welcomeMsg: $t("Commerce.Amazon.Copilot.DataQuery", [moduleName]),
          q: [$t("Commerce.Amazon.Copilot.DataQuery.RcmQst4")]
        }
      }
    ],
    [
      { path: /^\/Business\/Coupon.*/i, name: "Coupon" },
      (moduleName) => {
        return {
          welcomeMsg: $t("Commerce.Amazon.Copilot.DataQuery", [moduleName]),
          q: [$t("Commerce.Amazon.Copilot.DataQuery.RcmQst5")]
        }
      }
    ],
    [
      { path: /^\/Business\/Order.*/, name: "Order" },
      (moduleName) => {
        return {
          welcomeMsg: $t("Commerce.Amazon.Copilot.DataQuery", [moduleName]),
          q: [$t("Commerce.Amazon.Copilot.DataQuery.RcmQst6")]
        }
      }
    ]
  ])
}))

// 匹配到的物料类型 => api SuggestionWord controller 中的接口名
const materialApiMapAll = {
  amazon: {
    profile: "Profiles",
    portfolio: "Portfolios",
    campaign: "Campaigns",
    adgroup: "Adgroups",
    brand: "Brands",
    "campaign tag": "CampaignTags",
    "campaign tagging": "CampaignTags",
    "keyword tag": "KeywordTags",
    "keywords tag": "KeywordTags",
    "kw tag": "KeywordTags",
    "keyword tagging": "KeywordTags",
    "adgroup tag": "AdgroupTags",
    "adgroup tagging": "AdgroupTags",
    "asin tag": "AsinTags",
    "asin tagging": "AsinTags"
  },
  walmart: {
    profile: "Profiles",
    campaign: "Campaigns",
    adgroup: "Adgroups",
    brand: "Brands",
    "campaign tag": "CampaignTags",
    "campaign tagging": "CampaignTags",
    "keyword tag": "KeywordTags",
    "keywords tag": "KeywordTags",
    "kw tag": "KeywordTags",
    "keyword tagging": "KeywordTags",
    "item tag": "ItemTags",
    "item tagging": "ItemTags"
  }
}

const materialLabelMapAll = computed(() => ({
  amazon: {
    profile: $t("Profiles"),
    portfolio: $t("Portfolios"),
    campaign: $t("Campaigns"),
    adgroup: $t("Adgroups"),
    brand: $t("Brands"),
    "campaign tag": $t("Campaign Tags"),
    "campaign tagging": $t("Campaign Tags"),
    "keyword tag": $t("Keyword Tags"),
    "keywords tag": $t("Keyword Tags"),
    "kw tag": $t("Keyword Tags"),
    "keyword tagging": $t("keyword Tags"),
    "adgroup tag": $t("Adgroup Tags"),
    "adgroup tagging": $t("Adgroup Tags"),
    "asin tag": $t("ASIN Tags"),
    "asin tagging": $t("ASIN Tags")
  }
}))

const materialInputRegExpAll = {
  // 介词 (compulsory) + 定冠词 / 不定冠词 (optional) + 物料类型 (compulsory) + 任意单词
  amazon: /(for|in|of)\s+((a|an|the)\s+)?(profile|portfolio|campaign|adgroup|brand|campaign\s+tag|campaign\s+tagging|asin\s+tag|asin\s+tagging|keywords?\s+tag|kw\s+tag|keyword\s+tagging)\s([^\s]*)$/i
  // walmart: /(for|in|of)\s+((a|an|the)\s+)?(profile|campaign|adgroup|brand|campaign\s+tag|campaign\s+tagging|item\s+tag|item\s+tagging|keyword\s+tag|keyword\s+tagging)\s([^\s]*)$/i
  // dsp: /(for|in|of)\s+((a|an|the)\s+)?(advertiser|order|line\s+item|creative|audience|product|order\s+tag|order\s+tagging|creative\s+tag|creative\s+tagging|line\s+item\s+tag|line\s+item\s+tagging)\s([^\s]*)$/i
}

const materialReqRegExpAll = {
  // 介词 (compulsory) + 定冠词 / 不定冠词 (optional) + 物料类型 (compulsory) + 空格 (compulsory)
  amazon: /(for|in|of)\s+((a|an|the)\s+)?(profile|portfolio|campaign|adgroup|brand|campaign\s+tag|campaign\s+tagging|asin\s+tag|asin\s+tagging|keywords?\s+tag|kw\s+tag|keyword\s+tagging)\s+$/i
  // walmart: /(for|in|of)\s+((a|an|the)\s+)?(profile|campaign|adgroup|brand|campaign\s+tag|campaign\s+tagging|item\s+tag|item\s+tagging|keywords?\s+tag|kw\s+tag|keyword\s+tagging)\s+$/i
  // dsp: /(for|in|of)\s+((a|an|the)\s+)?(advertiser|order|line\s+item|creative|audience|product|order\s+tag|order\s+tagging|creative\s+tag|creative\s+tagging|line\s+item\s+tag|line\s+item\s+tagging)\s+$/i
}

const getProfileStorageConfig = ({ userId, productLine } = {}) => {
  const listKeyMapping = {
    amazon: "profileList",
    dsp: "profileList",
    walmart: "userProfiles"
  }
  const keyMapping = {
    amazon: "id",
    dsp: "profileId",
    walmart: "profileId"
  }
  return { profileListKey: listKeyMapping[productLine], profileKey: keyMapping[productLine] }
}

const getAdvertiserIds = async ({ userId, productLine, profileIds = [], jsonParam = {} } = {}) => {
  let advertiserIds = []
  if (productLine === "amazon") {
    advertiserIds = JSON.parse(localStorage.getItem("account"))?.map((item) => item.advertiserId)
  } else if (productLine === "dsp") {
    if (jsonParam.advertiserIds) {
      advertiserIds = jsonParam.advertiserIds
    } else {
      const res = await allCopilotApis.getAdvertisersByProfileIdsDSP({ profileIds: profileIds.concat(["-1"]), countryList: [] })
      advertiserIds = (res || []).map((item) => item.advertiserId)
    }
  }
  return advertiserIds || []
}

export {
  nameArr,
  metricI18nMapAll,
  unifyMetrics,
  displayFieldSpFiltering,
  insightPage,
  apiDataQueryNavMapAll,
  colors,
  insightTypeFieldMap,
  dataQueryGroupByMap,
  QUESTIONS_PRESETS_KB,
  QUESTIONS_PRESETS_DQ,
  materialInputRegExpAll,
  materialReqRegExpAll,
  getProfileStorageConfig,
  getAdvertiserIds,
  materialApiMapAll,
  materialLabelMapAll
}
