import { defineStore } from "pinia"
// store
import { useUserStore } from "@/store"
// service
import { getAWSKnowledgeBaseToken, getMaterialSuggestion } from "../src/Copilot/api"
// utils
import { initKbStreamingWebsocket } from "../utils/copilot"
import { union } from "lodash-es"
// constants
import { CONVERSATION_TYPE, SENDER } from "../src/Copilot/constants"
// config
import { materialApiMapAll, materialInputRegExpAll } from "../src/Copilot/config"

const useCopilotStore = defineStore("copilot", {
  state: () => ({
    // Knowledge Base Start
    kbToken: "",
    kbParam: {},
    kbContent: "",
    contextualConv: [],
    // Knowledge Base End
    // Data Query Input Prediction Start
    predPool: [], // Transformer 模型预测结果池
    materialPool: {}, // 物料预测池
    materialSelection: [] // 曾出现的物料选项总和
    // Data Query Input Prediction End
  }),
  actions: {
    // Knowledge Base Start
    async getKbIngredientsAndSave() {
      const userStore = useUserStore()
      const res = await getAWSKnowledgeBaseToken()
      this.kbToken = res.token
      this.kbParam = res.param
      this.resetContextualConv()
      if (window.copilotSocket) {
        window.copilotSocket.close()
        window.copilotSocket = null
      }
      await initKbStreamingWebsocket() // 开启 Feature Instruction (Knowledge Base) 的 websocket 连接
      // 从 sessionStorage 中读取对话上下文缓存, 初始化 contextualConv
      const data = JSON.parse(sessionStorage.getItem(`${userStore.user?.userId}_CopilotConversations`) || "{}")[CONVERSATION_TYPE.FEATURE_INSTRUCTION]
      this.contextualConv = (data || [])
        .slice(2)
        .slice(-6)
        .map((item) => {
          if (item.sender === SENDER.SELF || (item.sender === SENDER.MODEL && item.format !== "markdown")) {
            return { role: item.sender === SENDER.SELF ? "user" : "AI", content: item.message }
          } else {
            return { role: "AI", content: item.fullMessage }
          }
        })
    },
    resetKbIngredients() {
      this.kbToken = ""
      this.kbParam = {}
      this.kbContent = ""
      if (window.copilotSocket) {
        window.copilotSocket.close()
        window.copilotSocket = null
      }
    },
    setKbContent(content) {
      this.kbContent = content
    },
    resetKbContent() {
      this.kbContent = ""
    },
    appendContextualConv(convInfo) {
      this.contextualConv.push(convInfo)
      this.resetKbContent()
    },
    setContextualConv(conv) {
      this.contextualConv = conv
    },
    resetContextualConv() {
      this.contextualConv = []
    },
    // Knowledge Base End
    // Data Query Input Prediction Start
    updatePredPool(predictions) {
      // 拼接后去重
      this.predPool = Array.from(new Set(this.predPool.concat(predictions)))
      // 按单词数、字母数(EN)/字数(ZH) 进行升序排序
      this.predPool.sort((a, b) => a.split(" ").length - b.split(" ").length).sort((a, b) => a.length - b.length)
      // console.log("predictions pool >>>>>>>>>>>>>>", this.predPool)
    },
    async initMaterialPool() {
      const productLine = window.productline || localStorage.getItem("productline")
      if (productLine in materialInputRegExpAll) {
        const materialApiMap = materialApiMapAll[productLine]
        const keys = union(Object.values(materialApiMap))
        keys.map((key) => {
          getMaterialSuggestion(key).then((res) => (this.materialPool[key] = res))
        })
      }
      console.log("materials pool >>>>>>>>>>>>>>", this.materialPool)
    },
    updateMaterialPool(key, materials) {
      this.materialPool[key] = materials
    },
    updateMaterialSelection(materials) {
      this.materialSelection = Array.from(new Set(this.materialSelection.concat(materials)))
    }
    // Data Query Input Prediction End
  }
})

export default useCopilotStore
