import { app } from "../src/frame"
// config
import { QUESTIONS_PRESETS_KB, QUESTIONS_PRESETS_DQ } from "../src/Copilot/config"
// store
import useCopilotStore from "../store/copilot"
import { upperFirst } from "lodash-es"
// constants
import { INTENTION_TYPE, SENDER, STATUS_CODE } from "../src/Copilot/constants"

const getProductLine = () => window.productline || localStorage.getItem("productline")

const judgeActiveCopilotGuidingPopByRoute = () => {
  const route = app.config.globalProperties.router.currentRoute.value
  for (const entry of QUESTIONS_PRESETS_KB.value[getProductLine()]) {
    if (route.name === entry[0].name || entry[0].path.test(route.path)) {
      return true
    }
  }
  return false
}
const getCopilotQuestionsPresetsByRoute = (moduleName) => {
  const route = app.config.globalProperties.router.currentRoute.value
  const productLinePresets = QUESTIONS_PRESETS_KB.value[getProductLine()]

  for (const entry of productLinePresets) {
    if (route.name === entry[0].name || entry[0].path.test(route.path)) {
      let config = entry[1]
      // 处理数组类型配置（兼容旧版）
      if (Array.isArray(config)) {
        return {
          welcomeMsg: $t("amskey3303", [moduleName]),
          q: config
        }
      }
      // 处理对象类型配置
      if (typeof config === "object" && config !== null) {
        return config
      }
      // 处理函数类型配置
      if (typeof config === "function") {
        return config(moduleName)
      }
      return config
    }
  }
  return []
}

// 除了固定问答预设外，还有一些特殊的问答预设，如 Commerce 平台使用routePath来区分不同的问答预设
const getCopilotDataQueryPresetsByRoute = (moduleName) => {
  const route = app.config.globalProperties.router.currentRoute.value
  const productLinePresets = QUESTIONS_PRESETS_DQ.value[getProductLine()]
  // Map结构数据将读取配置
  if (productLinePresets instanceof Map) {
    for (const entry of productLinePresets) {
      // 可匹配到当前路由的配置
      if (route.name === entry[0].name || entry[0].path.test(route.path)) {
        let config = entry[1]
        // 处理数组类型配置
        if (Array.isArray(config)) {
          return {
            welcomeMsg: $t("amskey3301"),
            q: config
          }
        }
        // 处理对象类型配置
        if (typeof config === "object" && config !== null) {
          return config
        }
        // 处理函数类型配置
        if (typeof config === "function") {
          return config(moduleName)
        }
        return config
      }
    }
  }
  return {
    welcomeMsg: $t("amskey3301"),
    q: productLinePresets
  }
}

// 历史记录转为会话记录
const convertHistoryToSession = (queryType, queryData) => {
  let storageData = []
  switch (queryType) {
    // Data Query
    case INTENTION_TYPE.DATA_QUERY:
      queryData.forEach((d, index) => {
        let Answer = JSON.parse(d.Answer || {})
        let Question = d.Question
        // 提问
        storageData.push({
          type: "message",
          time: "",
          sender: SENDER.SELF,
          status: "",
          message: Question,
          allData: null
        })
        // 回答,错误码转为提示信息
        let { message = "", type = "message", status = "" } = convertCodeToMessage(Answer.Code, d.Message)
        storageData.push({
          type: type,
          time: "",
          sender: SENDER.MODEL,
          status: status,
          message:
            message ||
            `
                <div>${$t("We have found the relevant results for you. Please click the link to view them.")}</div>
                <div class='flex items-center mt-0.5 text-[var(--pac-theme-color)] cursor-pointer'>
                  <span class='action-chat' attr-data='data-query-result-${2 * index + 1}'>${upperFirst($t("click here"))}</span>
                  <span class='ml-1'><<</span>
                </div>
              `,
          allData: Answer.Data
        })
      })
      break
    // Insight
    case INTENTION_TYPE.INSIGHT:
      queryData.forEach((d, index) => {
        // Answer可能是字符串或对象
        let Answer = d.Answer
        let Question = d.Question
        try {
          Answer = JSON.parse(d.Answer || {})
        } catch (e) {
          console.error(e)
        }
        // 当Answer为字符串时，直接展示
        let RawMessage = typeof Answer === "string" ? Answer : Answer?.Data?.RawMessage
        // 提问
        storageData.push({
          type: "message",
          time: "",
          sender: SENDER.SELF,
          status: "",
          message: Question,
          allData: null
        })
        // 回答,错误码转为提示信息
        if (typeof Answer === "object" && Answer.Code != 200) {
          let { message = "" } = convertCodeToMessage(Answer.Code, Answer.Message)
          RawMessage = message
        } else {
          RawMessage = RawMessage.slice(0, RawMessage.lastIndexOf("**Refer to")).replace(/!\[.*?\]\(.*?\)/g, "")
        }
        storageData.push({
          type: "message",
          time: "",
          sender: SENDER.MODEL,
          status: "",
          message: RawMessage,
          allData: Answer.Data,
          format: "markdown"
        })
      })
      break
    // Feature Instruction
    case INTENTION_TYPE.FEATURE_INSTRUCTION:
      queryData.forEach((d) => {
        let { Answer, Question } = d
        storageData.push({
          type: "message",
          time: "",
          sender: SENDER.SELF,
          status: "",
          message: Question,
          allData: null
        })
        storageData.push({
          type: "message",
          time: "",
          sender: SENDER.MODEL,
          status: "",
          message: Answer,
          fullMessage: Answer,
          allData: null,
          format: "markdown_stream"
        })
      })
      break
  }
  return storageData
}
// 错误码转为提示信息
const convertCodeToMessage = (code, msg) => {
  let type = "message"
  let message = ""
  let status = ""
  switch (code) {
    case STATUS_CODE.ERROR:
      type = "alert"
      status = "failure"
      message = $t("Query invalid, Please check your input and try again.")
      break
    case STATUS_CODE.QUOTA_EXCEEDS:
      type = "message"
      status = "error_encountered"
      message = $t("amskey3261")
      break
    case STATUS_CODE.TAG_NOT_FOUND:
      type = "message"
      status = "error_encountered"
      message = $t("amskey3262")
      break
    case STATUS_CODE.INSIGHT_FORBIDDEN:
      //
      break
    case STATUS_CODE.DATA_QUERY_FORBIDDEN:
      type = "message"
      status = "error_encountered"
      message = msg
      break
  }
  return { message, type, status }
}

const generateUUID = () => {
  return "id-xxxxxxxxxxxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    const r = (Math.random() * 16) | 0
    const v = c === "x" ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

let reconnectCounter = 0
const initKbStreamingWebsocket = ({ isErrReconnect = false } = {}) => {
  return new Promise((resolve, reject) => {
    if (!isErrReconnect) {
      reconnectCounter = 0
    }
    const copilotStore = useCopilotStore()
    const ws = new WebSocket(`wss://xommhhbr24.execute-api.us-west-2.amazonaws.com/Prod?token=Bearer ${copilotStore.kbToken}`)
    // Closure
    let index = -1
    let isStreaming = true
    let fullContent = ""
    let displayContent = ""
    let displayEOF = false
    let imgEncountered = false
    let hyperlinkEncountered = false
    // 正则捕获组处理
    const regCaptureGroupsFn = (match, $1, $2) => ($2.startsWith("http") ? "" : $1)

    // listener
    ws.onopen = () => {
      console.log("Knowledge contextual conversation start.")
      resolve(true)
    }

    ws.onmessage = (e) => {
      if (!e.data) return
      reconnectCounter = 0
      index++
      const content = JSON.parse(e.data).text?.content ?? ""
      // 判断首次返回的 content 切片大小, 判定是否流式
      if (index === 0) {
        isStreaming = content.length < 20
      }
      if (isStreaming && content.includes("**Refer to")) {
        displayEOF = true
      }
      if (!displayEOF) {
        const prev = displayContent
        displayContent += content
        // 流式返回, 遇到图片 / 超链接, 实时过滤
        if (isStreaming) {
          if (imgEncountered || content.includes("![")) {
            displayContent = prev
            imgEncountered = true
          }
          if (hyperlinkEncountered || content.includes("[*")) {
            displayContent = prev
            hyperlinkEncountered = true
          }
          // 等待结束标志
          if ((imgEncountered || hyperlinkEncountered) && content.includes(")")) {
            imgEncountered = false
            hyperlinkEncountered = false
          }
        }
        // 非流式返回需要针对图片及超链接再做一次过滤
        if (!isStreaming) {
          displayContent = displayContent.replace(/\[(.*?)\]\((.*?)\)/g, regCaptureGroupsFn)
          displayContent = displayContent.replace(/!\[(.*?)\]\((.*?)\)/g, regCaptureGroupsFn)
        }
        copilotStore.setKbContent(displayContent)
      } else {
        // 中间的两个小括号分别对应 组1 组2
        displayContent = displayContent.replace(/\[(.*?)\]\((.*?)\)/g, regCaptureGroupsFn)
        displayContent = displayContent.replace(/!\[(.*?)\]\((.*?)\)/g, regCaptureGroupsFn)
        copilotStore.setKbContent(displayContent)
      }
      // [DONE] 结束标志是固定的
      if (content === "[DONE]") {
        app.config.globalProperties.$eventHub.emit("onKbStreamFinish")
        copilotStore.appendContextualConv({
          role: "AI",
          content: fullContent
        })
        fullContent = ""
        displayContent = ""
        displayEOF = false
        index = -1
        isStreaming = true
      } else {
        fullContent += content
      }
    }

    ws.onerror = (e) => {
      console.log("Knowledge contextual conversation error encountered", e)
      // 清除当前流式返回(若有)并重连
      if (reconnectCounter >= 3) {
        app.config.globalProperties.$eventHub.emit("onKbStreamError")
        app.config.globalProperties.$eventHub.emit("onStopGeneration")
      }
      fullContent = ""
      displayContent = ""
      displayEOF = false
      if (reconnectCounter < 3) {
        setTimeout(async () => {
          reconnectCounter++
          await initKbStreamingWebsocket({ isErrReconnect: true })
        }, 1000)
      }
    }

    ws.onclose = (e) => {
      console.log("Knowledge contextual conversation disconnected", e)
    }

    window.copilotSocket = ws
  })
}

export { judgeActiveCopilotGuidingPopByRoute, getCopilotQuestionsPresetsByRoute, getCopilotDataQueryPresetsByRoute, convertHistoryToSession, generateUUID, initKbStreamingWebsocket }
