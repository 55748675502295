import request from "@pacvue/frame/request"
const VITE_APP_BASEURL = import.meta.env.VITE_APP_BASEURL
const VITE_APP_SOV2 = import.meta.env.VITE_APP_SOV2
const VITE_APP_META = import.meta.env.VITE_APP_META
const VITE_APP_MENU_API = import.meta.env.VITE_APP_MENU_API
var VITE_APP_DOWNLOAD = import.meta.env.VITE_APP_DOWNLOAD
const VITE_APP_INDBASEURL = import.meta.env.VITE_APP_INDBASEURL

const productUrl = VITE_APP_SOV2
export function GetAdvertisingPageDataTotal(data, url, { isUseByColumn } = {}) {
  var baseUrl = isUseByColumn ? VITE_APP_INDBASEURL : VITE_APP_BASEURL
  // if (url == "AsinInfo/GetAsinTagDataTotal") {
  //   baseUrl = VITE_APP_SOV2
  // }
  var profileIds = data?.ProfileIds || data?.profileIds || []
  if (!profileIds.length) {
    var res = {
      code: 200,
      success: true,
      data: {
        originalBid: 0.0,
        isAuto: 0,
        errorCount: 0,
        notSetCount: 0,
        totalAsinCount: 0,
        setAsinCount: 0,
        unmatchedCount: 0,
        exceptionCount: 0,
        totalBudget: 0.0,
        showName: " []",
        bidAdjustment: 0,
        daypartingModel: null,
        scheduleModel: null,
        bidOptimization: false,
        sbBidAdjustment: null,
        bidMultiplier: 0.0,
        bidOptimizationStrategy: null,
        CampaignTagNames: "",
        placement: null,
        IsCampaignNote: false,
        NegativeKeywordListCount: 0,
        tactic: null,
        SuggestBudget: 0.0,
        AdGroupCount: 0,
        AdGroupActiveCount: 0,
        CreativeJson: null,
        BiddingStrategy: null,
        brandLogoUrl: "",
        CreativeProductAsins: [],
        ProductStatus: [],
        ActlAvl: 0,
        AvlBid: 0,
        ActlBid: 0,
        weekDay: "Mon.",
        weekDayShow: null,
        portfolioId: null,
        portfolioName: null,
        CampaignName: null,
        CampaignTag: null,
        CampaignTagCurrent: null,
        campaignId: null,
        campaignType: null,
        TargetingType: null,
        tagName: null,
        Type: null,
        creationDate: null,
        creationDateShow: null,
        DaypartingOn: null,
        targetingType: null,
        state: null,
        dailyBudget: 0.0,
        startDate: null,
        endDate: null,
        premiumBidAdjustment: false,
        biddingStrategy: null,
        biddingStrategyExcel: "--",
        productPagesAdjustPercentage: null,
        placementTopAdjustPercentage: null,
        servingStatus: null,
        crontabData: null,
        dayBudgetSetting: null,
        targetACOS: 0.0,
        targetROAS: 0.0,
        targetCPC: 0.0,
        targetCPA: 0.0,
        Rules: 0,
        BudgetSetting: 0,
        AiSetting: 0,
        TuneUp: 0,
        DayPartSet: 0,
        isBudgetSchedulerOn: 0,
        Target: "",
        xValueType: null,
        xValue: "01/01/0001",
        defaultBid: null,
        is1PAccount: null,
        IsControl: null,
        TotalBudget: 0.0,
        actualBidHours: 0,
        NumberOfWord: 0,
        CampaignTargetROAS: null,
        CampaignTargetACOS: null,
        CampaignTargetCPC: null,
        CampaignTargetCPA: null,
        costType: null,
        tagInAi: null,
        aiId: null,
        NewToBrandSalesCompar: null,
        NewToBrandSalesPercentageCompar: null,
        NewToBrandOrdersCompar: null,
        NewToBrandOrdersPercentageCompar: null,
        NewToBrandUnitsOrderedCompar: null,
        NewToBrandUnitsOrderedPercentageCompar: null,
        NewToBrandOrderRateCompar: null,
        NewToBrandSalesChange: null,
        NewToBrandSalesPercentageChange: null,
        NewToBrandOrdersChange: null,
        NewToBrandOrdersPercentageChange: null,
        NewToBrandUnitsOrderedChange: null,
        NewToBrandUnitsOrderedPercentageChange: null,
        NewToBrandOrderRateChange: null,
        ImpressionCompar: 0,
        ClickCompar: 0,
        SpendCompar: 0.0,
        SalesCompar: 0.0,
        ConversionCompar: 0,
        SaleUnitsCompar: 0,
        ViewImpressionCompar: 0,
        SponsoredDisplaySpendCompar: 0.0,
        CVRCompar: 0.0,
        TACOSCompar: null,
        TACOS: null,
        TACOSChange: null,
        CTRCompar: 0.0,
        CPCCompar: 0.0,
        CPACompar: 0.0,
        CPMCompar: 0.0,
        ACOSCompar: 0.0,
        ROASCompar: 0.0,
        VCPMCompar: 0.0,
        ImpressionChange: 0.0,
        ClickChange: 0.0,
        SpendChange: 0.0,
        SalesChange: 0.0,
        ConversionChange: 0.0,
        SaleUnitsChange: 0.0,
        ViewImpressionChange: 0.0,
        CVRChange: 0.0,
        CTRChange: 0.0,
        CPCChange: 0.0,
        CPAChange: 0.0,
        CPMChange: 0.0,
        ACOSChange: 0.0,
        ROASChange: 0.0,
        VCPMChange: 0.0,
        SameAsinSalesCompar: 0.0,
        SameAsinSales: 0.0,
        SameAsinSalesChange: 0.0,
        OtherSalesCompar: 0.0,
        OtherSalesChange: 0.0,
        OtherSalesPercentCompar: 0.0,
        OtherSalesPercentChange: 0.0,
        OrderPriceCompar: 0.0,
        OrderPriceChange: 0.0,
        PromotedSalesCompar: 0.0,
        PromotedSales: 0.0,
        PromotedSalesChange: 0.0,
        brandedSearchCompar: 0,
        brandedSearchChange: 0.0,
        brandedSearchViewCompar: 0,
        brandedSearchViewChange: 0.0,
        SameSkuACOSCompar: 0.0,
        SameSkuACOSChange: 0.0,
        SameSkuROASCompar: 0.0,
        SameSkuROASChange: 0.0,
        SameSkuCPACompar: 0.0,
        SameSkuCVRCompar: 0.0,
        SameSkuCPAChange: 0.0,
        SameSkuCVRChange: 0.0,
        SameSkuSalesCompar: 0.0,
        SameSkuSalesChange: 0.0,
        SameSkuConversionsCompar: 0,
        SameSkuConversionsChange: 0.0,
        SameSkuSaleUnitsCompar: 0,
        SameSkuSaleUnitsChange: 0.0,
        NewToBrandSales: 0.0,
        NewToBrandSalesPercentage: 0.0,
        NewToBrandOrders: 0.0,
        NewToBrandOrdersPercentage: 0.0,
        NewToBrandUnitsOrdered: 0.0,
        NewToBrandUnitsOrderedPercentage: 0.0,
        NewToBrandOrderRate: 0.0,
        addTime: "",
        Name: null,
        xValueInfo: null,
        currencySymbol: "$",
        countryAddress: "com",
        currencyCode: "",
        countryCode: "",
        reportDateTime: "0001-01-01T00:00:00",
        DateTime: "0001-01-01T00:00:00",
        Date: "01/01/0001",
        DaysShow: "",
        Days: "",
        WeekDate: "0-",
        WeekYear: "0-0",
        WeekYearDate: "",
        weekOfYear: 0,
        day: null,
        week: 0,
        weekShow: "W0,Jan-01-0001",
        month: 0,
        monthShow: "Jan-0001",
        quarter: 0,
        quarterShow: "Q0-0",
        year: 0,
        profileId: null,
        Impression: 0,
        SaleUnits: 0,
        Click: 0,
        Spend: 0.0,
        Sales: 0.0,
        Conversion: 0,
        OtherSales: 0.0,
        OtherSalesPercent: 0.0,
        OrderPrice: 0.0,
        ViewImpression: 0,
        SponsoredDisplaySpend: 0.0,
        SDvCPMSpend: 0.0,
        CVR: 0.0,
        CTR: 0.0,
        CPC: 0.0,
        SPC: 0.0,
        CPA: 0.0,
        CPM: 0.0,
        ACOS: 0.0,
        ROAS: 0.0,
        VCPM: 0.0,
        sellerStringId: "",
        brandName: "",
        aliasName: "",
        brandedSearch: 0,
        brandedSearchView: 0,
        SameSkuSales: 0.0,
        SameSkuConversions: 0,
        SameSkuSaleUnits: 0,
        SameSkuCVR: 0.0,
        SameSkuCPA: 0.0,
        SameSkuACOS: 0.0,
        SameSkuROAS: 0.0,
        cumulativeReach: 0.0,
        cumulativeReachCompar: 0.0,
        cumulativeReachChange: 0.0,
        avgImpressionsFrequency: 0.0,
        avgImpressionsFrequencyCompar: 0.0,
        avgImpressionsFrequencyChange: 0.0
      },
      msg: ""
    }
    return new Promise((resolve) => {
      return resolve(res.data)
    })
  }
  return request({
    url: baseUrl + url,
    data,
    method: "post",
    cancelToken: true
  })
}

export function GetAdvertisingChartData(data, url, { isUseByColumn } = {}) {
  url = url || "Profile/v3/GetProfileChartData"
  var baseUrl = isUseByColumn ? VITE_APP_INDBASEURL : VITE_APP_BASEURL
  // if (url == "AsinInfo/GetAsinTagChartData") {
  //   baseUrl = VITE_APP_SOV2
  // }
  var profileIds = data?.ProfileIds || data?.profileIds || []
  if (!profileIds.length) {
    return new Promise((resolve) => {
      return resolve([])
    })
  }
  return request({
    url: baseUrl + url,
    data,
    method: "post",
    cancelToken: true
  })
}

export function GetAdvertisingPageData(data, url, { isUseByColumn } = {}) {
  url = url || "Profile/v3/GetProfilePageData"
  var profileIds = data?.ProfileIds || data?.profileIds || []
  if (!profileIds.length) {
    var pageInfo = data?.PageInfo ?? data.pageInfo
    var OrderByField = pageInfo.orderByField
    var OrderAsc = pageInfo.orderAsc
    var PageSize = pageInfo.pageSize
    var res = {
      code: 200,
      success: true,
      data: { Data: [], PageInfo: { OrderByField, OrderAsc, TotalDataCount: 0, PageSize, PageIndex: 1, PageCount: 0, OrderString: `${OrderByField} ${OrderAsc ? "asc" : "desc"}` } },
      msg: ""
    }
    return new Promise((resolve) => {
      return resolve(res.data)
    })
  }
  const baseUrl = isUseByColumn ? VITE_APP_INDBASEURL : VITE_APP_BASEURL
  return request({
    url: baseUrl + url,
    data,
    method: "post",
    cancelToken: true
  })
}
window.downloadFileObj = {}
export function DownloadAdvertisingPageData(data, url, { isUseByColumn = false } = {}) {
  url = url || "Profile/v3/GetProfilePageData"
  var baseUrl = VITE_APP_DOWNLOAD
  if (url == "AsinInfo/GetAsinTagPageDataDownload") {
    baseUrl = VITE_APP_SOV2
  }
  return new Promise((resolve, reject) => {
    request({
      url: baseUrl + url,
      data,
      method: "post"
    })
      .then((res) => {
        if (res) {
          window.downloadFileObj[res] = true
        }
        res && resolve(res)
      })
      .catch((ex) => {
        reject(ex)
      })
  })
}
export function GetCampaignPageDataChild(data) {
  var url = "Campaign/v3/GetCampaignPageDataChild"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post",
    cancelToken: true
  })
}
export function GetPlacementCampaignPageDataChild(data) {
  var url = "Campaign/v3/GetPlacementCampaignPageDataChild"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post",
    cancelToken: true
  })
}
export function GetMaxDateTime(data) {
  return request({
    url: VITE_APP_BASEURL + "Fead/v3/GetMaxDateTime",
    data: data,
    method: "post"
  })
}

export function GetCampaignSubTagData(data) {
  var url = "CampaignTag/v3/GetCampaignSubTagData"
  var dim = data.dim
  if (dim != "ytd") {
    url = "CampaignTag/v3/GetCampaignSubTagGroupByData"
  }
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post",
    cancelToken: true
  })
}

export function GetAdGroupSubTagData(data) {
  var url = "AdGroupTag/v3/GetAdGroupSubTagData"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post",
    cancelToken: true
  })
}
export function GetAsinSubTagPageData(data) {
  var url = "AsinTag/v3/GetAsinSubTagPageData"
  var baseUrl = VITE_APP_BASEURL
  var dim = data.dim
  if (dim != "ytd") {
    url = "AsinTag/v3/GetAsinSubTagPageDataGroupByData"
  }
  return request({
    url: baseUrl + url,
    data,
    method: "post",
    cancelToken: true
  })
}
export function GetAdGroupTag(data) {
  return request({
    url: VITE_APP_BASEURL + "Tagging/v3/GetAdGroupTag",
    method: "GET",
    data
  })
}
export function GetKeywordTag(data) {
  return request({
    url: `${VITE_APP_META}apply/keywordTags`,
    data,
    method: "post"
  })
}
export function GetAllAsinTags(params) {
  return request({
    url: VITE_APP_SOV2 + "AdObject/GetAllAsinTags",
    params,
    method: "get"
  })
}
export function getUserList(data) {
  return request({
    url: `${VITE_APP_META}apply/getUserList`,
    method: "post",
    //fullData: true,
    data: data
  })
}

export function AddCampaignToPortfolio(data) {
  let url = "Portfolio/v3/AddCampaignToPortfolio"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function CreatePortfolio(data) {
  let url = "Portfolio/v3/CreatePortfolio"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function UpdataPortfolio(data) {
  let url = "Portfolio/v3/UpdataPortfolio"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function BulkUpdatePortfolio(data) {
  let url = "Portfolio/v3/BulkUpdatePortfolio"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
//asinDialog
export function GetCampaignByCampaignType(data) {
  let url = "Campaign/v3/GetCampaignByCampaignType"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function AdGroupSkuSave(data) {
  let url = "Adgroup/v3/AdGroupSkuSave"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function GetAdgroupsByCampaignIds(data) {
  let url = "Adgroup/v3/GetAdgroupsByCampaignIds"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function GetAdgroupByAsin(data) {
  let url = "ProductAd/v3/GetAdgroupByAsin"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function GetAllAsinTagsForAsin(data) {
  // let url = `AdObject/GetAllAsinTagsForAsin?isCheckEdit=${data.isCheckEdit}`
  let url = `AdObject/GetAllAsinTagsForAsin${data?.isCheckEdit ? "?isCheckEdit=" + data?.isCheckEdit : ""}`
  return request({
    url: productUrl + url,
    data,
    method: "get"
  })
}
export function CreateAsinTag(data) {
  let url = "AsinInfo/CreateAsinTag"
  return request({
    url: productUrl + url,
    data,
    method: "post"
  })
}
export function AddAsinsToAsinTagging(data) {
  let url = "AsinInfo/AddAsinsToAsinTagging"
  return request({
    url: productUrl + url,
    data,
    method: "post"
  })
}
export function GetAsinTagsByAsin(data) {
  let url = "AsinInfo/GetAsinTagsByAsin"
  return request({
    url: productUrl + url,
    data,
    method: "post"
  })
}
export function RemoveAsinsToAsinTagging(data) {
  let url = "AsinInfo/RemoveAsinsToAsinTagging"
  return request({
    url: productUrl + url,
    data,
    method: "post",
    fullData: true
  })
}
export function GetBrandAudit(data) {
  let url = "BrandAudit/GetBrandAudit"
  return request({
    url: productUrl + url,
    data,
    method: "post"
  })
}
export function InsertAsins2BrandAudit(data) {
  let url = "BrandAudit/InsertAsins2BrandAudit"
  return request({
    url: productUrl + url,
    data,
    method: "post"
  })
}
export function BulkAdGroupSkuReplace(data) {
  let url = "Adgroup/v3/BulkAdGroupSkuReplace"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function AddCampaigns(data) {
  let url = "PortfolioFromAmz/v3/AddCampaigns"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function getCampaignByProfile(data) {
  return request({
    url: `${VITE_APP_BASEURL}Campaign/v3/GetPageCampaignByProfile`,
    method: "post",
    //fullData: true,
    data: data
  })
}
export function BulkAddAsinsToCampaignAll(data) {
  let url = "Asin/BulkAddAsinsToCampaignAll"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}

export function RemoveAsinsFromAsinTagAll(data) {
  let url = "Asin/RemoveAsinsFromAsinTagAll"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function AddAsinsToTagAll(data) {
  let url = "Asin/AddAsinsToTagAll"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function BlukAddCampaigns(data) {
  let url = "PortfolioFromAmz/v3/BlukAddCampaigns"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function GetAdChart(data) {
  let url = "Ad/v3/GetAdChart"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function GetAdVesions(data) {
  let url = `Ad/v3/GetAdVesions?profileId=${data.profileId}&adId=${data.adId}`
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function GetAsinInfoV2(data) {
  let url = "Asin/GetAsinInfoV2"
  return request({
    url: VITE_APP_BASEURL + url,
    data,
    method: "post"
  })
}
export function saveEventLog(data) {
  let url = "user/saveEventLog"
  return request({
    url: VITE_APP_MENU_API + url,
    data,
    productline: "amazon",
    method: "post"
  })
}
export function GetAdGroupSubTagList(data) {
  var url = "AdGroupTag/GetAdGroupSubTagList"
  return request({
    url: VITE_APP_INDBASEURL + url,
    data,
    method: "post",
    cancelToken: true
  })
}
