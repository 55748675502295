import request from "@pacvue/frame/request"
import { VITE_APP_BASEURL, VITE_APP_KEYWORDTAGURL, VITE_APP_AllAsinTags } from "./index"
// 获取AMC列表
export function getAMCList(data) {
  return request({
    url: VITE_APP_BASEURL + "api/AmcReport/List",
    method: "post",
    data
  })
}

// 编辑AMC report name
export function editReportName(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/EditReportName",
    method: "post",
    data
  })
}

// 获取AMC历史列表
export function getAMCHistoryList(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/HistoryList",
    method: "post",
    data
  })
}

// 编辑AMC history report name
export function editHistoryReportName(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/EditHistoryReportName",
    method: "post",
    data
  })
}

// 删除AMC
export function deleteAMCReport(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/BulkDelete",
    method: "post",
    data
  })
}

// 创建AMCReport
export const createAMCReport = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/Create",
    method: "post",
    data
  })

// 编辑AMCReport
export const editAMCReport = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/Edit",
    method: "post",
    data
  })

export const amsReportDetail = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/Detail",
    method: "get",
    params
  })

// 获取DSP的Advertiser
export const dspAdvertiser = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetDSPAdvertiser",
    method: "get",
    params
  })

// 获取DSP的Order
export const dspOrder = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetDSPOrder",
    method: "post",
    data
  })

// 获取DSP的Profile
export const amcProfile = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMSProfile",
    method: "get",
    params
  })

// 获取DSP的Campaign
export const amcCampaign = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMSCampaign",
    method: "post",
    data
  })

// 获取DSP的CampaignTag
export const amcCampaignTag = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMSCampaignTag",
    method: "post",
    data
  })

export const amcAIPLConversionFunnel = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCAIPLConversionFunnel",
    method: "post",
    data
  })

export const amcAdTypeOverlap = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCAdTypeOverlap",
    method: "post",
    data
  })

export const amcDaypartingAnalysis = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCDaypartingAnalysis",
    method: "post",
    data
  })

export const amcDownloadAMCReport = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/DownloadAMCReportById",
    method: "post",
    data,
    responseType: "blob"
  })

// 立即执行
export const runNow = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/RunNow",
    method: "post",
    params
  })

// 获取最新的一条数据
export const lastHistory = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetLastHistory",
    method: "post",
    params
  })

// 重新执行
export const refreshHistory = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/RefreshHistory",
    method: "post",
    params
  })

export const getAMCInstance = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCInstance",
    method: "get",
    params
  })

export const getDSPOTT = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetDSPOTT",
    method: "post",
    data
  })

export const getAMCAssistedConversionsAnalysis = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCAssistedConversionsAnalysis",
    method: "post",
    data
  })

export const getAMCTopConversionPath = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCTopConversionPath",
    method: "post",
    data
  })

export const getAmcConsoleList = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/AmcConsoleLit",
    method: "post",
    data
  })

export const getAmcBulkAddDSP = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/AmcBulkAddDSP",
    method: "post",
    data
  })

export const getAmcBulkAddSP = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/AmcBulkAddSP",
    method: "post",
    data
  })

export const InstanceCreate = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/AmcConsoleCreate",
    method: "post",
    data
  })

export const getInstanceDetail = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/AmcConsoleDetail",
    method: "get",
    params
  })

export const AmcConsoleEdit = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/AmcConsoleEdit",
    method: "post",
    data
  })

export const AmcUserConsoleList = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/AmcUserConsoleLit",
    method: "post",
    data
  })

export const getClientList = () =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetClientList",
    method: "get"
  })

export const getUserList = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetUserList",
    method: "get",
    params
  })
export const GetV2UserList = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetUserList",
    method: "post",
    data
  })

export const GetInstanceDetail = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetInstanceDetail",
    method: "get",
    params
  })
export const GetBindUsers = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetBindUsers",
    method: "get",
    params
  })
export const GetV2ClientList = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetClientList",
    method: "get",
    params
  })

export const getAmcInstanceTree = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAmcInstanceTree",
    method: "post",
    params
  })
export const ChangeInstanceStatus = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/ChangeInstanceStatus",
    method: "post",
    data
  })
export const BindUserInstance = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/BindUserInstance",
    method: "post",
    data
  })
export const UnbindUserInstance = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/UnbindUserInstance",
    method: "post",
    data
  })

export const AmcUserConsoleCreate = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/AmcUserConsoleCreate",
    method: "post",
    data
  })

export const AmcUserConsoleDetail = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/AmcUserConsoleDetail",
    method: "get",
    params
  })

export const editUserConsole = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/AmcUserConsoleEdit",
    method: "post",
    data
  })
export const delAmcUserConsole = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/AmcUserConsoleDelete",
    method: "post",
    data
  })

// 获取 viewMode
export const getViewMode = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetViewMode",
    method: "get",
    params
  })

// Brand Keyword Search 图表接口
export const getBrandKeywordSearch = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCBrandKeyword",
    method: "post",
    data
  })

export const getAMCBrandKeywordByDate = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCBrandKeywordByDate",
    method: "post",
    data
  })

// Suggested Budget Allocation 图表接口
export const getSuggestedBudgetAllocation = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCSuggestedBudgetAllocation",
    method: "post",
    data
  })

// ThreeTopSegments Table 接口
export const getThreeTopSegments = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCTopSegmentsInsight",
    method: "post",
    data
  })

// Top Conversion Path 新加 Tag 接口
export const getTopConversionPathTag = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCTagConversionPath",
    method: "post",
    data
  })

export const downloadAMCBrandKeyword = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/DownloadAMCBrandKeyword",
    method: "post",
    data,
    responseType: "blob"
  })

export const downloadAMCTopSegmentsInsight = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/DownloadAMCTopSegmentsInsight",
    method: "post",
    data,
    responseType: "blob"
  })

export const getAMCConsiderationPeriod = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCConsiderationPeriod",
    method: "post",
    data
  })

export const getAMCRepurchasedPeriod = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCRepurchasedPeriod",
    method: "post",
    data
  })

export const getAMCDemographics = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCDemographics",
    method: "post",
    data
  })

export const getAMCDemographicsLocation = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCDemographicsLocation",
    method: "post",
    data
  })

export const getAMCPersonaUnTargetSegment = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCPersonaUnTargetSegment",
    method: "post",
    data
  })

export const getAMCPersonaTargetSegment = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCPersonaTargetSegment",
    method: "post",
    data
  })

export const getAMCPersonaMyList = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCPersonaMyList",
    method: "get",
    params
  })

export const operateAMCPersonaMyList = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/OperateAMCPersonaMyList",
    method: "post",
    data
  })

export const deleteAmcHistory = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/DeleteHistory",
    method: "delete",
    fullData: true,
    params
  })

export const getAMCNTBAnalysis = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCNTBAnalysis",
    method: "post",
    data
  })

export const getAMCLongTermAnalysis = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCLongTermAnalysis",
    method: "post",
    data
  })
export const AuthAmcInstance = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/AuthAmcInstance",
    method: "post",
    data
  })
export const RefreshAmcInstance = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/RefreshAmcInstance",
    method: "post",
    data
  })
export const GetAmcInstanceList = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetAmcInstanceList",
    method: "post",
    data
  })
export const GetAMCLTVCAC = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCLTVCAC",
    method: "post",
    data
  })
export const GetAudienceList = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcAudience/AudienceList",
    method: "post",
    data
  })
export const GetAMCAdvertiser = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCAdvertiser",
    method: "post",
    data
  })
export const AudienceMappingList = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcAudience/AudienceMappingList",
    method: "post",
    data
  })
export const UpdateAudienceMapping = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcAudience/UpdateAudienceMapping",
    method: "post",
    data
  })

export const createAmcAudience = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcAudience/CreateAudience",
    method: "post",
    data
  })

export function getKeywordTags(data) {
  return request({
    url: `${VITE_APP_KEYWORDTAGURL}/apply/keywordTags`,
    method: "post",
    data
  })
}

export function getASINTags(params) {
  return request({
    url: `${VITE_APP_AllAsinTags}/api/AdObject/GetAllAsinTags`,
    method: "get",
    params,
    headers: {
      productline: "dsp"
    }
  })
}

export function getCampaignTags(data) {
  return request({
    url: `${VITE_APP_KEYWORDTAGURL}/apply/campaignTagsByProfile`,
    method: "post",
    data
  })
}

export function getAMCInstanceByAdvertiserId(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCInstanceByAdvertiserId",
    method: "get",
    params
  })
}

export function GetAMCMarketingFunnelRate(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCMarketingFunnelRate",
    method: "post",
    data
  })
}
export function GetAMCMarketingFunnelTrend(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCMarketingFunnelTrend",
    method: "post",
    data
  })
}
export function UpdateInstanceDetail(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/UpdateInstanceDetail",
    method: "post",
    data
  })
}
export function GetSupportedFunnelDashboardTagType(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetSupportedFunnelDashboardTagType",
    method: "get",
    params
  })
}
export function DownloadAMCMarketingFunnelTrend(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/DownloadAMCMarketingFunnelTrend",
    method: "post",
    data,
    responseType: "blob"
  })
}
export function DownloadAMCMarketingFunnelRate(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/DownloadAMCMarketingFunnelRate",
    method: "post",
    data,
    responseType: "blob"
  })
}

export function GetShareToken(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetShareToken",
    method: "get",
    params
  })
}

export function ExpandAudience(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcAudience/ExpandAudience",
    method: "post",
    data
  })
}

export function GetShoppingVisiable(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetShoppingVisiable",
    method: "get",
    params
  })
}

export function GetAdsIndirectImpact(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAdsIndirectImpact",
    method: "post",
    data
  })
}

export function getSnSSAnalysis(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetSnSS",
    method: "post",
    data
  })
}

export function GetPurchasersAndNTB(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetPurchasersAndNTB",
    method: "post",
    data
  })
}

export function getBasketAnalysis(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetBasket",
    method: "post",
    data
  })
}

export function getRepurchasedPeriod(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetRepurchasedPeriod",
    method: "post",
    data
  })
}

export function getConsiderationPeriod(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetConsiderationPeriod",
    method: "post",
    data
  })
}

export function getTopConversionPath(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetTopConversionPath",
    method: "post",
    data
  })
}
export function GetFunnelExploration(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetFunnelExploration",
    method: "post",
    data
  })
}
export function ValidateCreate(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/ValidateCreate",
    method: "post",
    data
  })
}
export function getCrossSellingAnalysis(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetCrossSellingAnalysis",
    method: "post",
    data
  })
}
export function getEntityList(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetEntityList",
    method: "get",
    params
  })
}
export function downloadUploadCampaignsTemplate(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/DownloadUploadCampaignsTemplate",
    method: "get",
    params,
    responseType: "blob"
  })
}
export function uploadCustomCampaigns(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/UploadCustomCampaigns",
    method: "post",
    data
  })
}
export function getCustomCampaignList(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetCustomCampaignList",
    method: "post",
    data
  })
}
export function downloadCustomCampaignList(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/DownloadCustomCampaignList",
    method: "post",
    data,
    responseType: "blob"
  })
}

export function getSelfUploadCampaign(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetSelfUploadCampaign",
    method: "post",
    data
  })
}

export function getSelfUploadCampaignTag(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetSelfUploadCampaignTag",
    method: "post",
    data
  })
}

export function ChangeState(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/ChangeState",
    method: "put",
    data
  })
}

export function lastHistoryExploration(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetLastHistory",
    method: "post",
    data
  })
}

export function getExplorationHistoryList(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/HistoryList",
    method: "post",
    data
  })
}

export function getExplorationList(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/List",
    method: "post",
    data
  })
}

export function getExplorationViewMode(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetViewMode",
    method: "get",
    params
  })
}

export function getCrossSellingAnalysisExploration(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetCrossSellingAnalysis",
    method: "post",
    data
  })
}

export function getAMCAIPLConversionFunnel(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetAMCAIPLConversionFunnel",
    method: "post",
    data
  })
}

export const amcExplorationAdTypeOverlap = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetAMCAdTypeOverlapComparison",
    method: "post",
    data
  })

export const getAMCLTVCACExploration = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetAMCLTVCAC",
    method: "post",
    data
  })

export const getAMCDaypartingAnalysisExploration = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetAMCDaypartingAnalysis",
    method: "post",
    data
  })

export const getAMCDemographicsComparison = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetAMCDemographicsComparison",
    method: "post",
    data
  })

export const AiInsightQuery = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/AiInsightQuery",
    method: "post",
    data
  })

export const GetAMCDaypartingAnalysisComparison = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetAMCDaypartingAnalysisComparison",
    method: "post",
    data
  })

export const DownloadAMCReportByIdExploration = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/DownloadAMCReportById",
    method: "post",
    data,
    responseType: "blob"
  })

export const GetReachAndImpFrequency = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetReachAndImpFrequency",
    method: "post",
    data
  })

export const getTopConversionPathExploration = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetTopConversionPath",
    method: "post",
    data
  })

export const getAudiencePenetration = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAudiencePenetration",
    method: "post",
    data
  })

export const getAMCAudienceList = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/DiscoveryV2/AMCAudienceList",
    method: "post",
    data
  })

export const getAmcAccounts = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetAmcAccounts",
    method: "get",
    params
  })

export const getRepeatSnSSData = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetRepeatSnSS",
    method: "post",
    data
  })

// v3.2
export const getAudienceDetail = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcAudience/Detail",
    method: "get",
    params
  })

// v3.2
export const getListDataset = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcAudience/ListDataset",
    method: "post",
    data
  })

//3.2
export const GetAMCNTBGatewayASINs = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetAMCNTBGatewayASINs",
    method: "post",
    data
  })

// v3.2
export const getCLTVAnalysisDetail = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetCLTVAnalysis",
    method: "post",
    data
  })
export function getOnAndOffAmazonConversionPath(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetOnAndOffAmazonConversionPath",
    method: "get",
    params
  })
}
export function getDataUploadSetList(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetDataSet",
    method: "post",
    data
  })
}
export function deleteDataUpload(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/DeleteDataSet",
    method: "post",
    data
  })
}
export function createDataSet(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/CreateDataSet",
    method: "post",
    data
  })
}
export function checkAWSAmazonS3Info(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/CheckAWSAmazonS3Info",
    method: "post",
    data
  })
}
export function updateDataSet(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/UpdateDataSet",
    method: "post",
    data
  })
}
export function getDataSetDetail(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetDataSetDetail",
    method: "get",
    params
  })
}
export function createDataUpload(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/CreateDataUpload",
    method: "post",
    data
  })
}
export function getLastedS3Setting(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetLastedS3Setting",
    method: "get",
    params
  })
}
export function download1PDataUploadTemplate(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/Download1PDataUploadTemplate",
    method: "get",
    params: params,
    responseType: "blob"
  })
}
export function getDataUploadLogList(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetDataUpload",
    method: "post",
    data
  })
}
export const History = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/MyReport/History",
    method: "post",
    data
  })
export const checkMicrosoftAzureInfo = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/MyReport/CheckMicrosoftAzureInfo",
    method: "post",
    data
  })
export const newVisFTP = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/MyReport/CheckFtpInfo",
    method: "post",
    data
  })
export function getAMCCustomSqlExplorationList(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetAMCCustomSqlExplorationList",
    method: "post",
    data
  })
}
export function generateS3UploadUrl(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/generateS3UploadUrl",
    method: "post",
    data
  })
}
export function getGMOverView(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetGMOverView",
    method: "post",
    data
  })
}
export function getGMNTBOverView(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetGMNTBOverView",
    method: "post",
    data
  })
}

export function getGMChannelData(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetGMChannelData",
    method: "post",
    data
  })
}
export function getGMWeeklyTrendData(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetGMWeeklyTrendData",
    method: "post",
    data
  })
}
export function getGMFrequencyData(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetGMFrequencyData",
    method: "post",
    data
  })
}
export function getGMDaypartData(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetGMDaypartData",
    method: "post",
    data
  })
}
export function getAMCDateSetByType(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetAMCDateSetByType",
    method: "post",
    data
  })
}
export function getGMNTBWeeklyTrendData(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetGMNTBWeeklyTrendData",
    method: "post",
    data
  })
}
export function getGMNTBChannelData(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetGMNTBChannelData",
    method: "post",
    data
  })
}
export function getOneAndOffAmazonOverlap(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetOnAndOffAmazonOverlap",
    method: "get",
    params
  })
}
export function getTop50SearchTermAnalysis(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetTop50SearchTermAnalysis",
    method: "get",
    params
  })
}
export function deleteAudience(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcAudience/DeleteAudience",
    method: "post",
    data
  })
}

export function updateAudienceData(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcAudience/UpdateAudience",
    method: "post",
    data
  })
}
export function GetAmcAccount(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcOAuth2/GetAmcAccount",
    method: "post",
    data
  })
}

export function getOnePAudienceOptions(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcAudience/GetOnePAudienceOptions",
    method: "get",
    params
  })
}

export function getBandedSize(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/PersonaAudienceInsight/GetBandedSize",
    method: "post",
    data
  })
}

export function getDemographics(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/PersonaAudienceInsight/GetDemographics",
    method: "post",
    data
  })
}

export function getExportAll(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/PersonaAudienceInsight/ExportAll",
    method: "post",
    data,
    responseType: "blob"
  })
}

export function getTopCategoriesPurchased(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/PersonaAudienceInsight/GetTopCategoriesPurchased",
    method: "post",
    data
  })
}

export function getPrimeVideo(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/PersonaAudienceInsight/GetPrimeVideo",
    method: "post",
    data
  })
}

export function getTopOverlappingAudiences(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/PersonaAudienceInsight/GetTopOverlappingAudiences",
    method: "post",
    data
  })
}

export function getAMCDailyTrend(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetAMCNtbDailyTrend",
    method: "post",
    data
  })
}

export function getSTVIncremental(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetStvIncremental",
    method: "post",
    data
  })
}

export function getStvOverlap(data) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReport/GetStvOverlap",
    method: "post",
    data
  })
}

export function getShareId(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetShareId",
    method: "get",
    params
  })
}

export function getShareTokenAMC(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetShareToken",
    method: "get",
    params
  })
}

// 获取audience的Advertiser
export const audienceAdvertiser = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcAudience/GetAdvertisers",
    method: "post",
    data
  })
export const CreateAMCInstance = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/CreateAMCInstance",
    method: "post",
    data
  })
export const GetAmcInstanceCreationList = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetAmcInstanceCreationList",
    method: "post",
    data
  })

export const getCrossPurchaseList = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetCrossPurchaseList",
    method: "post",
    data
  })

export const getCrossPurchaseChart = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetCrossPurchaseChart",
    method: "post",
    data
  })
export function GetAmcInstanceCreationDetail(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetAmcInstanceCreationDetail",
    method: "get",
    params
  })
}
export const UpdateAMCInstance = (data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/UpdateAMCInstance",
    method: "post",
    data
  })
export function GetInstanceCreationUserList(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/GetInstanceCreationUserList",
    method: "get",
    params
  })
}
export function CheckInstanceCreationResult(params) {
  return request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/CheckInstanceCreationResult",
    method: "get",
    params
  })
}
export const DeleteAMCInstance = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/DeleteAMCInstance",
    method: "delete",
    fullData: true,
    params
  })

export const getCrossPurchaseAsinList = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcExploration/GetCrossPurchaseAsinList",
    method: "get",
    params
  })
export const DownloadAMCInstanceCreationTemplate = (params) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/DownloadAMCInstanceCreationTemplate",
    method: "get",
    responseType: "blob",
    params
  })
export const BulkCreateAMCInstance = (params, data) =>
  request({
    url: VITE_APP_BASEURL + "/api/AmcReportV2/BulkCreateAMCInstance",
    method: "post",
    data,
    params
  })
export const getStrategies = (params) =>
  request({
    url: VITE_APP_BASEURL + "api/AmcAudience/Strategies",
    method: "get",
    params
  })
